/* feed.module.css */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --sidebar-width: 280px;
  --content-margin: 20px;
  --sidebar-main-gap: 30px;
  --main-bg-color: #121212;
  --sidebar-bg-color: #1A1A1A;
  --header-bg-color: #2b2b2b;
  --search-bg-color: #2D2D2D;
  --text-color: #E0E0E0;
  --secondary-text-color: #888;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--main-bg-color);
  color: var(--text-color);
}

.loadingContainer{
  display: flex;
  justify-content: center;
  margin-top: 20vh; 
  height: 100%;
  width: 100%;

}

.parentContainer {
 background-color: none;
}
.headerBox {
  display: flex; 
  background-color: #1a1a1a;
  border: 1px solid #3c3c3c;
  border-radius: 12px;
  padding: 15px 20px;
  width: calc(100% - 70px);
  margin: 15px;
  margin-bottom: 5px; 
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headerBox h2 {
  font-family: 'Outfit';
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-left: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.headerBox h2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 70%;
  background: linear-gradient(to bottom, #4a90e2, #63b3ed);
  border-radius: 2px;
}

.headerButtons {
  display: flex;
  gap: 10px;
}

.headerButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.headerButton.active {
  background-color: #4a90e2;
  color: white;
}

.headerButton svg {
  margin-right: 8px;
}

.headerButton.primary {
  background-color: #e86e22;
  color: white;
}

.headerButton.primary:hover {
  background-color: #3a7bc8;
}

.feedContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: var(--content-margin);
  box-sizing: border-box;
}

.feedSidebar {
  background-color: var(--sidebar-bg-color);
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 15px;
}

.feedMainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: var(--sidebar-main-gap);
  min-width: 0; /* Allows content to shrink below its default minimum content size */
}

.divider{
  padding: 10px; 
}

.searchBar, .searchBarAlternate {
  display: flex;
  align-items: center;
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 10px 15px;
  width: 94%; 
  margin-bottom: 0px; 
  border: 1px solid #4a4a4a;
}

.searchbar{
  justify-content: space-between;
}

.searchBarAlternate {
  padding: 0px 10px;
  width: 100%; 
  border: none; 
  margin: 0; 
  flex-grow: 1;
  box-sizing: border-box;
}

.searchInputWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.searchWords {
  color: var(--secondary-text-color);
  margin-right: 10px;
  flex-shrink: 0;
}

.buttonContainer {
  flex-grow: 1;
  display: flex;
  min-width: 0; /* Allows input to shrink */
}

.searchInput {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 14px;
  padding: 5px;
  width: 100%; 
  min-width: 200px;
}

.searchInput:focus {
  outline: none;
}

.searchInput::placeholder {
  color: var(--secondary-text-color);
}

.resultsCount {
  font-size: 14px;
  color: var(--secondary-text-color);
  white-space: nowrap;
}

.feedContent {
  flex-grow: 1;
  overflow-y: auto;
}

/* Scrollbar styles */
.feedSidebar::-webkit-scrollbar,
.feedMainContent::-webkit-scrollbar,
.feedContent::-webkit-scrollbar {
  width: 6px;
}

.feedSidebar::-webkit-scrollbar-thumb,
.feedMainContent::-webkit-scrollbar-thumb,
.feedContent::-webkit-scrollbar-thumb {
  background-color: #4A4A4A;
  border-radius: 3px;
}

.feedSidebar::-webkit-scrollbar-track,
.feedMainContent::-webkit-scrollbar-track,
.feedContent::-webkit-scrollbar-track {
  background-color: var(--sidebar-bg-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .feedContainer {
    flex-direction: column;
    padding: 10px;
  }

  .feedSidebar {
    width: 100%;
    height: auto;
    max-height: 300px;
    padding-right: 0;
    margin-bottom: var(--content-margin);
  }

  .feedMainContent {
    margin-left: 0;
  }
}
/* feed.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.emptyState{
  color: rgba(255, 255, 255, 0.468); 
  text-align: center;
  font-weight: bolder;
  margin: 10px; 
  padding: 10px; 
  font-size: 25px;
}

.emptyButton{
  display: inline-flex; 
  align-items: center;
  gap: 10px; 
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: 10px;
  white-space: nowrap;
}

.emptyButton:hover {
  background-color: #3a7bc8;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.paginationButton{
  background-color: #4a90e2;
  color: white;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginationButton:hover {
  background-color: #3a7bc8;
}


.searchButton {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;
  margin-left: 10px;
}

.searchButton:hover {
  background-color: #3a7bc8;
}

.searchButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
}

.searchButton:active {
  background-color: #2c5d99;
}

@media (max-width: 768px) {
  .searchButton {
    padding: 6px 12px;
    font-size: 12px;
  }
}
.pageNum{
  color: white; 
}
