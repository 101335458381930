@import url("https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap"); 
@import url("https://fonts.googleapis.com/css2?family=Jacquarda+Bastarda+9&display=swap");




.profileImage {
    border-radius: 50%;
    background-color: #343434;
    display: flex;
    font-family: "Jacquard 12"; 
    font-weight: lighter;
    align-items: center;
    justify-content: center;
    border: 5px solid #2d2d2d;
    color: #bababa; 
}

.profileImage.large {
    width: 80px;
    height: 80px;
    font-size: 70px;
}

.profileImage.medium {
    width: 60px;
    height: 60px;
    font-size: 50px;
    border: 4px solid #2d2d2d;

}

.profileImage.small {
    width: 40px;
    height: 40px;
    font-size: 22px;
    border: 2px solid #434343;
    background-color: #515151;
    color: rgb(231, 231, 231); 
}



