.sidebar {
    width: 300px;
    height: 91vh;
    background-color: #333;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin: 10px;
    overflow: hidden;
    position: sticky;
    top: 10px;
  }
  
  .sidebarHeader {
    padding: 20px;
    background-color: #2a2a2a;
    border-bottom: 1px solid #444;
  }
  
  .sidebarTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    letter-spacing: 12px;
  }
  
  .currentGroup {
    background-color: #444;
    padding: 1% 15px 15px 15px;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .currentGroup h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  .currentGroup p {
    font-size: 14px;
    color: #aaa;
    margin: 0;
  }
  
  .searchContainer {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 10px 20px 20px 0px;
    background-color: none;
    width: 96%;
    border-bottom: 1px solid #444;
    margin-bottom: 10px;
  }

  .searchAndCreate {
    border-bottom: 1px solid #444;
    display: flex;
    flex-wrap: wrap;
  }

  .createGroupButton {
    display: inline-flex; 
    align-items: center;
    gap: 10px; 
    background-color: #60a7ff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px;
    margin-bottom: 15px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    width: 100%; 
  }
  
  .searchIcon {
    position: absolute;
    left: 10px;
    top: 42%;
    transform: translateY(-50%);
    color: #aaa;
  }
  
  .searchInput {
    width: 85%;
    padding: 10px 10px 10px 40px;
    border-radius: 20px;
    border: none;
    background-color: #444;
    color: #ffffff;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .searchInput:focus {
    outline: none;
    background-color: #555;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
  
  .searchInput::placeholder {
    color: #888;
  }
  
  .sidebarNav {
    padding: 20px;
    background-color: #2a2a2a;
  }
  
  .sidebarItem {
    list-style-type: none;
    margin-bottom: 10px;
  }
  
  .sidebarLink {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    text-decoration: none;
    color: #ffffff;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .sidebarLink:hover {
    background-color: #444;
  }
  
  .sidebarItem.active .sidebarLink {
    background-color: rgba(74, 144, 226, 0.2);
    color: #4a90e2;
  }
  
  .sidebarIcon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sidebarText {
    font-size: 16px;
    flex-grow: 1;
  }
  
  .sidebarCount {
    background-color: #444;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
  }
  
  .groupsList {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #333;
    margin-top: 0px;
  }
  
  .groupsList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .groupItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #2a2a2a;
  }
  
  .groupItem:hover {
    background-color: #444;
  }
  
  .groupInfo {
    display: flex;
    flex-direction: column;
  }
  
  .groupName {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .groupMembers {
    font-size: 12px;
    color: #aaa;
    margin-top: 2px;
  }
  
  .joinButton {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
  }
  
  .joinButton:hover {
    background-color: #3a7bc8;
  }
  
  .groupArrow {
    color: #aaa;
  }
  
  .discoverSection p {
    margin-bottom: 15px;
    color: #aaa;
    font-size: 14px;
  }
  

  /* Scrollbar styles */
  .groupsList::-webkit-scrollbar {
    width: 8px;
  }
  
  .groupsList::-webkit-scrollbar-track {
    background: #2a2a2a;
  }
  
  .groupsList::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
  }
  
  .groupsList::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
      margin: 0;
      border-radius: 0;
    }
  
    .sidebarHeader, .searchContainer, .sidebarNav {
      padding: 15px;
    }
  
    .groupsList {
      max-height: 50vh;
    }
  }