@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Tiny5&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap"); 
@import url("https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.nav {
  background: linear-gradient(to bottom, rgba(18, 18, 18, 1), rgba(18, 18, 18, 0.1));
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-family: 'Inconsolata', monospace;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
  transition: all 0.3s ease;
  z-index: 1000;
}

.nav > div:not(.logoContainer) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  padding-right: 40px;
}


.notificationButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
}

.button, .clickedButton, .logoutButton {
  color: #bababa;
  gap: 5px; 
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 17px;
  font-weight: bolder;
  font-family: 'Inconsolata', monospace;
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 110px;
}
.clickedButton{
  color: white; 
}

.logoutButton {
  padding: 10px;
  min-width: 50px;
  max-width: 60px;
}


.clickedButton {
  background: rgba(255, 255, 255, 0.062);
  border-color: rgba(255, 255, 255, 0.2);
}
.logoIcon{
  height: 45px; 
  width: auto; 
}

.logoButton {
  margin-left: 0px;
  color: white;
  font-family: 'Outfit', sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  letter-spacing: 5px;
}

.logoButton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.logoButton:hover::after {
  transform: translateX(100%);
  cursor: pointer; 
}
.logoContainer {
  margin-right: auto;
  display: inline-flex; 
  align-items: center;

}

.siloButton {
  color: white; 
  background: linear-gradient(135deg,  #4c7a9d, #244782);
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  font-family: "Inconsolata", system-ui;
  margin: 5px;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(38, 141, 226, 0.3);
  position: relative;
  overflow: hidden;
  display: inline-block;
  min-width: 120px;
  text-align: center;
  letter-spacing: 10px;
  max-width: 200px;
  border: none;
  outline: none;
}

.siloButton::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(315deg, #ffffff, #2196f3);
  z-index: -1;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.siloButton:hover::before {
  opacity: 1;
}

.siloButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 12px rgba(33, 150, 243, 0.4);
}

.siloButton:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.4);
}

.siloButton::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 2;
}

@keyframes glowing {
  0% { text-shadow: 0 0 5px rgba(255, 255, 255, 0.7); }
  50% { text-shadow: 0 0 20px rgba(255, 255, 255, 0.9); }
  100% { text-shadow: 0 0 5px rgba(255, 255, 255, 0.7); }
}

.siloButton:hover::after {
  animation: glowing 2s infinite;
}


.button:hover, .logoutButton:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.3);
}

.button:active, .logoutButton:active {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(1px);
}


.icon {
  margin-right: 8px;
  font-size: 1.1em;
  vertical-align: middle;
}

.logoutIcon {
  font-size: 1.1em;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: stretch;
  }

  .nav > div {
    margin-top: 10px;
  }

  .button, .clickedButton, .logoutButton, .siloButton {
    width: calc(50% - 10px);
    min-width: unset;
  }
}

@media (max-width: 480px) {
  .button, .clickedButton, .logoutButton, .siloButton {
    width: 100%;
  }
}