.workHistoryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.title {
  font-size: 48px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.noHistory {
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.saveButton {
  background-color: #4da6ff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #3a8ad6;
}

.saveButton:active {
  background-color: #2c6eb3;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
}