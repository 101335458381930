

.groupMembersContainer {
    padding: 10px;
    border-radius: 5px;
  }
  
  .groupTitle {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .membersList {
    list-style-type: none;
    padding: 0;
  }
  

  
  .memberItem:last-child {
    border-bottom: none;
  }

  
  