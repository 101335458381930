.videoContainer {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
  
.videoPreview {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 8px;
    background-color: #000;
  }

.videoPreview video {
    width: 100%;
    height: 100%;
  }