

.profileContainer {
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.profileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #D9D9D9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.userTypeTag {
  background-color: #b9d9eb;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 25px;
  color: #1e1e1e;
}

.firstColBox {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.topRowBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.interestsBox {
  display: flex;
  flex-direction: row;
}

.interestListBox {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  max-height: 75px;
  max-width: 300px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Optional: Add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.interestListBox::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
}
.interestListBox::-webkit-scrollbar-thumb {
  background: #88888878; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
.interestListBox::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}

.interestsTagStyle {
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 17px;
  max-height: 50px;
  width: auto;
  background-color: #b9d9eb;
  border-radius: 8px;
  padding: 5px;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
  display: inline-block; /* Adjust div width based on text content */
}

.interestsTagStyle:hover {
  background-color: #7faacb;
  cursor: pointer;
}

.bioAndSkillsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bioButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  font-family: inherit;
  padding: 0;
  text-decoration: underline;
  align-self: flex-start;
  color: #007bff;
}

.bioButton:hover {
  color: #0056b3;
  text-decoration: none;
}

.bioContainer {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.bioAndSkills {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.skillListBox {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  max-height: 80px;
  max-width: 300px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Optional: Add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}
.skillListBox::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
}
.skillListBox::-webkit-scrollbar-thumb {
  background: #88888878; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
.skillListBox::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}

.skillTagStyle {
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 17px;
  max-height: 50px;
  width: auto;
  background-color: #b9d9eb;
  border-radius: 8px;
  padding: 5px;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
  display: inline-block; /* Adjust div width based on text content */
}

.skillTagStyle:hover {
  background-color: #7faacb;
  cursor: pointer;
}

.profileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.buttonStyle {
  padding: 8px 1px;
  background-color: #b9d9eb;
  color: #1e1e1e;
  border: none;
  cursor: pointer;
  height: 60px;
  width: 140px;
  border-radius: 8px;
  font-size: 17px;
  margin-right: 10px;
  margin-top: 10px
}

.buttonStyle:hover {
  background-color: #7faacb;
}

.userName {
  margin-left: 20px;
}

.otherStudentProfileContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.bigButton {
  display: flex;
  font-family: 'inconsolata';
  font-weight: bolder; 
  background-color: rgb(29, 29, 29); 
  color: #D9D9D9;
  cursor: pointer;
  height: 50px;
  width: 200px;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  align-items: center;
  justify-content: center;
  border: none; 
  border-radius: 10px; 
  gap: 20px; 
  border-left: 2px solid rgb(125, 125, 125);
  border-right: 2px solid rgb(125, 125, 125);
  margin: 10px auto; /* Centers the button horizontally */

}

.bigButton:hover {
  background: linear-gradient(to bottom, #2c2c2c, #2e2e2e);
  color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.344);
}

.bigButton:focus {
  outline: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}


.sectionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to left, #e4e4e4, #d6d6d6);
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px); /* Make the element 40px thinner */
  margin-left: auto; /* Center the element */
  margin-right: auto; /* Center the element */
  box-sizing: border-box;
}
.sectionBar h2 {
  margin: 0;
}

.sectionBar:hover {
  background: linear-gradient(to left, #e8e8e8, #dedede);
}