.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.navBar {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #e0e0e0;
  margin-left: 35px;
}

.navLink {
  margin-left: 20px;
  cursor: pointer;
  color: #7faacb;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #6b91b0;
}

.active {
  font-weight: bold;
  text-decoration: underline;
}

.feedContainer {
  flex-grow: 1;
  width: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #e0e0e0;
}

/* Remove unused styles */