
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@font-face {
  font-family: 'minionpro-medium';
  src: url('../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.profileContainer {
  /*background-color: #212121;*/
  background-image:linear-gradient(to bottom, rgba(46, 46, 46, 0.4), rgba(30, 30, 30, 0.0));
  color: #f0f0f0;
  padding: 30px 40px 5px 50px;
  border-radius: 25px;
  margin: 20px 20px 0px 20px;
}

.profileHeader {
  display: flex;
  flex-direction: column;
}

.topSection {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.userInfoContainer{
  margin-top: 5px;
  display: inline-flex; 
  align-items: center;
  gap: 10px; 
}

.nameSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.userName {
  font-family: 'minionpro-medium';
  font-size: 37px;
  margin: 0 0 5px 0;
  text-align: left;
  letter-spacing: 5px;
}

.copyConfirmation {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1500;
  transition: opacity 0.3s ease-in-out;
}

.userInfo {
  font-size: 1.2rem;
  margin: 0;
  color: #bbb;
}

.tagsContainer {
  margin-top: 10px; 
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 20px;
}

.tagSection {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.tagLabel {
  font-weight: bold;
  color: #64b5f6;
  padding: 4px 8px 4px 0px;
  border-radius: 12px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #878787;
  margin-top:0px;
  margin-bottom:20px;  /* Space around the divider */
}

.tag {
  background-color: rgba(100, 181, 246, 0.1);
  color: #64b5f6;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.moreTagsContainer {
  background-color: rgba(100, 181, 246, 0.1);
  color: #64b5f6;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.85rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  position: relative;
  margin-right: 10px; 
}

.moreButton {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.hoverModal {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: rgb(25, 25, 25, .95);
  background: none; 
  backdrop-filter: blur(5px);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  min-width: 150px;
  max-width: 300px;
  flex-wrap: wrap;
  gap: 5px;
}

.moreTagsContainer:hover .hoverModal {
  display: flex;
  position: absolute;
  left: -200%; 
}

.bioContainer {
  margin: 0px 10px 10px 5px;
}

.bio {
  font-family: 'Outfit'; 
  font-weight: 200;
  font-size: 17px;
  line-height: 2;
  margin: 0 0 10px 0;
  text-align: justify;
  cursor: pointer; 
}

.bioButton {
  position: relative; 
  top: -20px;
  left: 98%;
  background-color: #282b30;
  background-color: rgba(100, 181, 246, 0.1);
  border-radius: 40px;
  padding: 7px; 
  border: none;
  color: #8fb8d9;
  cursor: pointer;
  font-size: 14px;
}

.linksContainer {
  margin-top: 10px; 
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px; 
  margin-right: 10px;
}

.linkButton {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #282b30;
  color: #f0f0f0d9;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  max-width: fit-content;
}

.linkButton:hover {
  background-color: #4a4a4a;
}
.contactMeButton{
  max-width: fit-content;
  display: flex; 
  align-items: center;
  gap: 10px; 
  margin-left: 20px; 
  background-color: #282b30;
  color: #f0f0f0d9;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.analyzeButton{
  display: flex; 
  align-items: center;
  gap: 10px; 
  background-color: rgba(100, 181, 246, 0.1);
  color: #64b5f6;
  text-decoration: none;
  padding: 8px 13px;
  border-radius: 20px;
  font-size: 18px;
  border: 1px solid #64b5f6;
  cursor: pointer;
  transition: background-color 1s ease;
}

.analyzeButton:hover {
  background-color: rgba(100, 180, 246, 0.378);
  border: 1px solid #7ec5ff;
  transition: background-color 1s ease;
}

.loadingError {
  font-size: 14px;
  color: #ff6b6b;
  text-align: center;
  padding: 8px;
}

.closeButton {
  position: absolute;
  top: 20px;
  align-content: center;
  right: 100px;
  background: rgb(252, 67, 0);
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white; 
  border-radius: 80px;
}

.closeButton2 {
  position: absolute;
  top: 30px;
  left: 30px; 
  align-content: center;
  background: rgb(255, 85, 0);
  border: none;
  width: 35px; 
  font-size: 1rem;
  cursor: pointer;
  color: white; 
  border-radius: 80px;
  padding: 8px; 
}

.closeButton:hover {
  background: rgb(174, 47, 0);
}

.tag:hover {
  background-color: #4a4a4a;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.resumeModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalScore {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}


.modalContent {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 1100px;
  max-height: 90vh;
  overflow-y: auto;
}


@media (max-width: 768px) {
  .topSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .tagsContainer {
    flex-direction: column;
  }
}


.resumePlaceholder {
  color: #f0f0f0;
  font-size: 1.2em;
  text-align: center;
  padding: 20px;
}

.shareProfileButton {

  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #282b30;
  color: #f0f0f0d9;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 10;
}

.shareProfileButton:hover {
  background-color: #4a4a4a;
}

