.discussionBoardContainer {
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 89%;
  width: 1100px; 
}

.boardTitle {
  font-size: 28px;
  color: #4a90e2;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 10px;
}

.commentSection {
  margin-bottom: 30px;
}

.commentTitle {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #4a90e2;
  font-weight: 600;
  text-transform: capitalize;
  border-left: 3px solid #4a90e2;
  padding-left: 10px;
}

.commentList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.commentCard {
  background-color: #2a2a2a;
  padding: 12px 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.commentCard:hover {
  transform: translateX(5px);
}

.commentText {
  margin-bottom: 8px;
  line-height: 1.4;
  font-size: 0.95em;
}

.commentAuthor {
  font-style: italic;
  color: #4a90e2;
  text-align: right;
  font-size: 0.85em;
}

.commentInputContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.commentInput {
  flex: 1;
  padding: 8px 12px;
  background-color: #333;
  border: 1px solid #4a90e2;
  border-radius: 5px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 0.9em;
}

.addCommentButton {
  padding: 8px 15px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
  font-weight: bold;
}

.addCommentButton:hover {
  background-color: #357ab7;
}

.loading, .noComments {
  text-align: center;
  color: #4a90e2;
  font-style: italic;
}