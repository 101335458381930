@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.projectContainer {
  padding: 35px 50px 20px 40px;
  border-radius: 15px;
  background-color: #171717;
  color: #d9d9d9;
  box-shadow: 0 4px 8px rgba(245, 242, 242, 0.1);
  box-sizing: border-box;
}

.createdByContainer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.commentText {
  color: #b8b8b8;
  font-size: 1rem;
  margin: 0; 
  margin-top: 2px;
}

.createdByContainer a {
  margin-left: 20px;
  color: #1e1e1e;
  font-size: 25px;
  text-decoration: none;
  font-weight: bolder;
}

.projectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.headerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.titleAndUsernameContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.projectTitle {
  font-family: 'Montserrat'; 
  font-weight: 700; 
  font-size: 1.7rem;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 0 20px 10px 0;
  width: 100%;
  word-wrap: break-word;
  hyphens: auto;
}

.byUsername {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 10px;
}

.username {
  font-weight: 600;
  color: #4a90e2;
  cursor: pointer;
}

.tagsContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-width: 120px;
  margin-bottom: 15px;;
}

.tag {
  background-color: #4a4a4a;
  color: #84b3e8;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: .9rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.tag:hover {
  background-color: #d0e0fc;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 15px 0;
}

.tagsDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 0;
  margin-left: 10px;
  justify-content: flex-start;
}

.tagStyle {
  background-color: #b9d9eb;
  color: #2d2d2d;
  padding: 6px 12px;
  border-radius: 5px;
}

.contentContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.bubble {
  background-color: #f4f2f2;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.bubble:hover {
  transform: translateY(-5px);
}

.carouselContainer {
  width: 100%;
}

.projDescription,
.additionalInfo,
.otherFieldDisplay,
.comments {
  margin-bottom: 15px;
}

.mediaImage {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  gap: 10px;
}

.upvoteSectionBox {
  display: flex;
  justify-content: left;
  margin-top: 15px;
}

.upvoteButtonBox {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 15px; 
}

.upvoteButton {
  background-color: #eae6e6;
  color: #70b9e3;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.upvoteButton:hover {
  background-color: #1e1e1e;
}

.clickedUpvoteButton {
  background-color: #4a90e2;
  color: white;
  border-radius: 5px;
  border-color: #3a7abd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
  margin-right: 20px;
}

.linkButton {
  background-color: #272727;
  color: #70b9e3;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
}

.linkButton:hover {
  background-color: #1e1e1e;
}

.editButton {
  display: inline-flex;
  align-items: center;
  gap: 10px; 
  padding: 7px 15px;
  background-color: #1c1c1c;
  border: 1px solid rgba(255, 255, 255, 0.495);
  border-radius: 15px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
  margin-left: 0;
  margin-top: 10px;
}

.editButton:hover {
  color: white;
  background-color: #3a70ae;
}

.commentBox {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #272727;
  padding: 5px 10px;
  border-radius: 15px;
  height: 40px; 
  margin: 20px 0;
  flex-grow: 1;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.commentIcon {
  color: #70b9e3;
  font-size: 1.2rem;
  margin-left: 5px; 
}

.commentText {
  color: #b8b8b8;
  font-size: 1rem;
}

.expandButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #70b9e3;
  font-size: 1.2rem;
  margin-left: auto;
}

.expandButton:hover {
  color: #1e1e1e;
}

.upvoteAndCommentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
  gap: 5px;  
  width: 100%; 
}

.commentsSection {
  background-color: #242424;
  padding: 10px;
  border-radius: 10px;
  margin-top: 0px;
  width: 98%;
}

.comment {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #515151;
}

.comment:last-child {
  border-bottom: none;
}

.commentProfileSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.commentContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.commentUser {
  font-weight: bold;
  color: #c0c0c0;
}

.commentUpvoteSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.commentUpvoteButton {
  background-color: #eae6e6;
  color: #70b9e3;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.commentUpvoteButton:hover {
  background-color: #1e1e1e;
}

.commentUpvoteCount {
  color: #ff3c00;
}

.addCommentSection {
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.commentInput {
  padding: 10px;
  background-color: #1d1d1d;
  color: white; 
  border: 1px solid #7c7c7ca1;
  border-radius: 8px;
  width: 90%;
  resize: vertical;
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 0px;
  overflow: hidden;
}

.commentInput:focus {
  border-color: #70b9e3;
  outline: none;
}

.addCommentButton {
  background-color: #1985c4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  align-self: flex-end;
  transition: background-color 0.3s ease;
  margin-bottom: 2px;
}

.addCommentButton:hover {
  background-color: #1e1e1e;
}

.captionBox {
  width: 100%;
  background-color: #f4f2f2;
  color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
}

.projectDescriptionContainer {
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.projDescription {
  font-family: 'Outfit'; 
  font-weight: 300; 
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.811); 
  line-height: 1.5;
  border-left: 5px solid #3498db;
  border-radius: 5px; 
  padding-left: 10px; 
  text-align: justify;
}

.collapsedDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMoreButton {
  background-color: #33373971;
  border: none;
  color: #cfcfcf;
  cursor: pointer;
  font-size: .75rem;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 20px;
  display: block;
  text-align: center;
}

.seeMoreButton:hover {
  background-color: #70b9e37b;
  color: #fff;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #cccccc79;
  margin: 20px 0;
}

@media (min-width: 768px) {
  .titleAndUsernameContainer {
    flex-wrap: nowrap;
    width: auto;
  }

  .projectTitle {
    width: auto;
    margin-bottom: 0;
  }

  .byUsername {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .editButton {
    margin-left: 25px;
    margin-top: 0;
  }
}

