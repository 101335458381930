

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: none; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px; /* Increased max-width */
  color: white; 
}

.modalContent {
  padding: 20px; 
  position: relative;
  background-color: #3c3c3c;
  color: white; 
  border-radius: 12px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  font-weight: bolder;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
  color: white; 
}

.input,
.textarea {
  width: 96%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #54595c;
  color: white; 
}

.textarea {
  height: 100px;
  resize: vertical;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.saveButton {
  background-color: #4171ff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.saveButton:hover {
  background-color: #185da8;
}
