@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

:root {
  --primary-bg: #1e1e1e;
  --secondary-bg: #2d2d2d;
  --primary-text: #D9D9D9;
  --secondary-text: #A2A2A2;
  --border-color: #6A6969;
  --highlight-bg: #85bcdb;
  --highlight-bg-hover: #6ca3c0;
  --danger-bg: #ff4c4c;
  --input-bg: #3a3a3a;
}

.containerWrapper {
  width: 100%;
  min-height: 70vh;
  background-color: var(--primary-bg);
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  box-sizing: border-box;
}

.addTagsAndLinks {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 40px;
  border-top: 1px solid var(--border-color);
  padding-top: 20px; 
}
.linkInput, .tagInput {
  width: 98%;
  padding: 0.75rem;
  margin-bottom: .5rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.subTitle{
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 0; 
  color: var(--highlight-bg);
  text-align: left;
}

.container {
  width: 90%;
  max-width: 1200px;
  color: var(--primary-text);
  font-family: Arial, sans-serif;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0; 
  color: var(--highlight-bg);
  text-align: left;
}

.projectInfo {
  margin-bottom: .5rem;
}

.projectNameInput,
.projectDescriptionInput,
.fileInput{
  width: 98%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.fileInput{
  width: auto;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.cellTextArea {
  width: auto;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.headerTextArea {
  width: auto;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.cellTypeSelect {
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%;
}

.languageSelect{
  cursor: pointer;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: var(--input-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%;

}

.projectNameInput:focus,
.projectDescriptionInput:focus,
.fileInput:focus,
.cellTypeSelect:focus {
  width: 98%; 
  outline: none;
  border-color: var(--highlight-bg);
  box-shadow: 0 0 0 2px rgba(133, 188, 219, 0.2);
}

.cellTextArea:focus {
  width: auto; 
  outline: none;
  border-color: var(--highlight-bg);
  box-shadow: 0 0 0 2px rgba(133, 188, 219, 0.2);
}

.projectDescriptionInput {
  min-height: 100px;
  resize: vertical;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: stretch;
}

.cell {
  flex: 1;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.cellHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.header{
  margin-bottom: 1.2rem;
  margin-top: 1rem; 
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--border-color); 
  padding-bottom: 1.2rem;

}

.cellTypeSelect {
  flex-grow: 1;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.removeCellButton {
  background-color: #1e1e1e;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.removeCellButton:hover {
  background-color: #ff6b6b;
}

.cellTextArea {
  min-height: 100px;
  resize: vertical;
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
}

.previewContainer {
  margin-top: 1rem;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.saveButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addContentBelowButton {
  background-color: #2d2d2d;
  border: 1px dashed #6a6969; 
  height: 50px; 
  width: 100%; 
  display: flex; 
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #a2a2a2; 
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative; 
  font-size: 1rem;
}

.iconSpacing { 
  margin-right: 0.5rem;
  margin-left: 0.5rem; 
}

.addContentRightButton{
  padding: 0.5rem 1rem;
  max-width: 80px;
  border: 1px dashed #6a6969;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 0.5rem;
  height: 80px;
  background-color: #2d2d2d;
  color: #a2a2a2;
  flex-direction: column; 
}

.addContentRightButton:hover,
.addContentBelowButton:hover {
  background-color: #262626;
  transform: translateY(-2px);
}

.addContentRightButton::before,
.addContentBelowButton::before {
  margin-right: 0.3rem;
  font-size: 1.1rem;
}

.addContentBelowButton {
  margin-right: 1rem;
}

.saveButton {
  background-color: var(--secondary-bg);
  color: var(--primary-text);
  border: 1px solid var(--highlight-bg);
}

.saveButton:hover {
  background-color: var(--highlight-bg);
  color: var(--primary-bg);
  transform: translateY(-2px);
}

.fileInput {
  cursor: pointer;
}

.fileInput::file-selector-button {
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  background-color: var(--secondary-bg);
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fileInput::file-selector-button:hover {
  background-color: var(--input-bg);
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  
  .cell {
    width: 100%;
  }
  
  .addContentRightButton {
    width: 100%;
    margin-top: 0.5rem;
    margin-left: 0;
  }
  
  .actionButtons {
    flex-direction: column;
    gap: 1rem;
  }
  
  .saveButton {
    width: 100%;
  }

}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.deleteButton{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-bg);
  color: var(--primary-text);
  border: 1px solid var(--highlight-bg);
}

.deleteButton:hover {
  background-color: var(--highlight-bg);
  color: var(--primary-bg);
  transform: translateY(-2px);
}

.modalDeleteButton {
  background: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modalKeepButton {
  background: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.spinner {
  border: 16px solid rgba(243, 243, 243, 0.2); /* Light grey, semi-transparent */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 52%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(2px);
  z-index: 1000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.autofillInput {
  display: none;
}

.autofillLabel {
  font-family: 'Lexend'; 
  font-weight: bold;
  background-color: #3498db;
  display: inline-flex; 
  color: white;
  padding: 15px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 20px;
  white-space: nowrap;
  max-height: 15px;
  border: 2px solid #96d5ff;
  align-items: center; 
  gap: 20px; 
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.autofillLabel:hover {
  background-color: #0b9dff;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(11, 157, 255, 0.7);
}

.moveModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(45, 45, 45, 0.95);
  padding: 2rem;
  border-radius: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  z-index: 1000;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: fadeIn 0.3s ease-out;
  min-width: 50px;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translate(-50%, -60%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
}

.moveModal button {
  padding: 0.75rem;
  background-color: rgba(58, 58, 58, 0.8);
  color: var(--primary-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.moveModal button:hover:not(:disabled) {
  background-color: var(--highlight-bg);
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(133, 188, 219, 0.5);
}

.moveModal button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.moveModal button:active:not(:disabled) {
  transform: scale(0.95);
}

.moveModal button svg {
  width: 20px;
  height: 20px;
}

.moveModal button:nth-child(1) { grid-column: 2; }
.moveModal button:nth-child(2) { grid-column: 1; grid-row: 2; }
.moveModal button:nth-child(3) { grid-column: 3; grid-row: 2; }
.moveModal button:nth-child(4) { grid-column: 2; grid-row: 3; }
.moveModal button:nth-child(5) {
  grid-column: 1 / span 3;
  grid-row: 4;
  border-radius: 25px;
  background-color: rgba(255, 76, 76, 0.8);
  color: white;
  font-weight: bold;
  width: auto;
  height: auto;
  margin-top: 1rem;
}

.moveModal button:nth-child(5):hover {
  background-color: rgba(255, 76, 76, 1);
}

.moveCellButton {
  background-color: transparent;
  color: var(--primary-text);
  border: 1px solid var(--border-color);
  border-radius: 7px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.moveCellButton:hover {
  background-color: rgba(133, 188, 219, 0.2);
  border-color: var(--highlight-bg);
}

.moveLabel{
  color: var(--highlight-bg);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 0; 
  text-align: left;
}


.headerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* This will right-align the content */
  gap: 20px; /* Adjust as needed */
}

.previewButton {
  background-color: #3498db;
  display: inline-flex;
  color: white;
  padding: 15px;
  border-radius: 5px;
  min-height: 10px;
  cursor: pointer;
  font-size: 20px;
  white-space: nowrap;
  border: 2px solid #96d5ff;
  align-items: center;
}

.previewButton:hover {
  background-color: #236895;
  color: white;
}

.previewModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 3000; /* Ensure it's on the highest z-index */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.modalContent {
  text-align: center;
}