@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #1E1E1E;
}

.background {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.861) 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.container {
  margin: 100px auto;
  padding: 32px;
  max-width: 400px;
  background: radial-gradient(circle, rgba(40, 46, 57, 0.827) 0%, rgba(45, 45, 45, 0) 75%) !important;
  border-radius: 40px;
  text-align: center;
  color: #E0E0E0;
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 16px;
}

.siloStyle {
  color: #D9D9D9;
  margin: 0 0 32px;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 48px;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 0.3em;
  margin-left: 12px;
}

.siloStyle:hover {
  color: #A2A2A2;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #1e1e1e !important;
  color: white !important;
  padding: 12px 0 !important;
  border-radius: 12px !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-family: 'Inconsolata', monospace !important;
  margin-bottom: 16px !important;
  margin-top: 5px; 
  width: 200px !important;
  transition: background-color 0.3s, transform 0.3s !important;
  border: none !important;
  cursor: pointer !important;
}

.forgotPasswordButton{
  background-color: #1e1e1e !important;
  color: white !important;
  padding: 12px 0 !important;
  border-radius: 12px !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-family: 'Inconsolata', monospace !important;
  margin-bottom: 16px !important;
  width: 150px !important;
  transition: background-color 0.3s, transform 0.3s !important;
  border: none !important;
  cursor: pointer !important;
  margin-top: 5px; 
}

.forgotPasswordButton:hover{
  background-color: #3d3d3d !important;
}

.button:hover {
  background-color: #333333 !important;
  transform: translateY(-3px) !important;
}

.loginButton, .createButton, .submitButton {
  background-color: #181818 !important;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22) !important;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.547) !important;
  border: 1px solid rgba(173, 216, 230, 0.3) !important;
  position: relative !important;
  overflow: hidden !important;
}

.loginButton::before, .createButton::before, .submitButton::before {
  content: '' !important;
  position: absolute !important;
  top: -50% !important;
  left: -50% !important;
  width: 200% !important;
  height: 200% !important;
  background: radial-gradient(circle, rgba(173,216,230,0.1) 0%, rgba(173,216,230,0) 70%) !important;
  transform: scale(0) !important;
  transition: transform 0.5s, opacity 0.5s !important;
  opacity: 0 !important;
}

.loginButton:hover::before, .createButton:hover::before, .submitButton:hover::before {
  transform: scale(1) !important;
  opacity: 1 !important;
}

.loginButton:hover, .createButton:hover, .submitButton:hover {
  background-color: #161616 !important;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22) !important;
  transform: translateY(-3px) !important;
}

.loginButton:active, .createButton:active, .submitButton:active {
  transform: translateY(2px) !important;
  box-shadow: 0 0 10px rgba(173, 216, 230, 0.5), 0 0 20px rgba(173, 216, 230, 0.3) !important;
}



.formInfoContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
}

.inputContainer {
  position: relative !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}

.inputContainer input {
  width: 100% !important;
  padding: 12px 12px 12px 40px !important;
  background-color: #161616 !important;
  border: none !important;
  border-radius: 8px !important;
  color: #E0E0E0 !important;
  font-family: 'Inconsolata', monospace !important;
  font-size: 16px !important;
  transition: background-color 0.3s !important;
  box-sizing: border-box !important;
  text-align: center !important;
}

.inputContainer input::placeholder {
  color: #A2A2A2 !important;
  align-items: center; 
}

.inputContainer input:focus {
  background-color: #4a4a4a !important;
  outline: none !important;
}

.inputIcon {
  position: absolute !important;
  left: 12px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #A2A2A2 !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.error {
  color: #ff6b6b !important;
  margin-top: 16px !important;
  font-size: 14px !important;
}

.usernameInput {
  width: 100% !important;
  padding: 12px 12px 12px 40px !important;
  background-color: #3c3c3c !important;
  border: none !important;
  border-radius: 8px !important;
  color: #E0E0E0 !important;
  font-family: 'Inconsolata', monospace !important;
  font-size: 16px !important;
  transition: background-color 0.3s !important;
  box-sizing: border-box !important;
}

.passwordInput {
  width: 100% !important;
  padding: 12px 12px 12px 40px !important;
  background-color: #3c3c3c !important;
  border: none !important;
  border-radius: 8px !important;
  color: #E0E0E0 !important;
  font-family: 'Inconsolata', monospace !important;
  font-size: 16px !important;
  transition: background-color 0.3s !important;
  box-sizing: border-box !important;
}

@media (max-width: 600px) {
  .container {
    position: absolute !important;
    top: 30% !important;
    left: 50% !important;
    transform: translate(-50%, -30%) !important;
    padding: 24px !important;
    max-width: 90% !important;
  }

  .siloStyle {
    font-size: 36px !important;
  }

  .button {
    width: 180px !important;
  }
}
