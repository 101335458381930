@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.profileContainer {
  background-color: #252525;
  color: #ffffff;
  padding: 30px;
  border-radius: 16px;
  margin: 20px auto;
  max-width: 90vw;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.topSection {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.infoContainer {
  flex: 1;
}

.userName {
  font-size: 2.5rem;
  margin: 0 0 10px 0;
  font-weight: 700;
  color: #64b5f6;
  text-align: left;
}

.userInfo {
  font-size: 1.rem;
  margin: 5px 0;
  color: #b0b0b0;
  white-space: nowrap;
}

.divider {
  height: 1px;
  background-color: #333;
  margin: 20px 0;
}

.bioSection {
  position: relative;
  margin-bottom: 20px;
}

.bio {
  font-size: 1rem;
  line-height: 1.9;
  margin: 0;
  color: #d0d0d0;
  cursor: pointer; 
  font-family: 'Lato';
}

.bioToggle {
  background: none;
  border: none;
  color: #64b5f6;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
  position: absolute;
  right: 0;
  bottom: -20px;
}

.tagsContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.tagSection {
  flex: 1;
}

.tagLabel {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #64b5f6;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: rgba(100, 181, 246, 0.1);
  color: #64b5f6;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.85rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.tag:hover {
  background-color: rgba(100, 181, 246, 0.2);
}

.buttonContainer {
  display: flex;
  gap: 15px;
}

.resumeButton, .githubButton {
  background-color: #64b5f6;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

.resumeButton:hover, .githubButton:hover {
  background-color: #42a5f5;
}

.loadingError {
  font-size: 1rem;
  color: #ff6b6b;
  text-align: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .topSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .userName {
    text-align: left;
  }

  .userInfo {
    white-space: normal;
  }

  .tagsContainer {
    flex-direction: column;
  }

  .buttonContainer {
    flex-direction: column;
  }

  .resumeButton, .githubButton {
    width: 100%;
  }
}


.resumeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.closeButton {
  position: absolute;
  top: 30px;
  left: 30px; 
  background: rgb(255, 85, 0);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white; 
  border-radius: 50%;
  padding: 8px; 
}

.closeButton:hover {
  background: rgb(174, 47, 0);
}

.resumePlaceholder {
  color: #f0f0f0;
  font-size: 1.2em;
  text-align: center;
  padding: 20px;
}
