@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.signupContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
}

.gameOfLifeWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.signupFormWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupForm {
  width: auto;
  min-width: 400px;
  padding: 25px 40px;
  border-radius: 40px;
  color: #E0E0E0;
  font-weight: bold;
  backdrop-filter: blur(3px);
  background-color: #7777771d;

}

.signUpFormOld{
  background: radial-gradient(circle, rgba(40, 46, 57, 0.9) 0%, rgba(45, 45, 45, 0.8) 75%);
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.3);
}

.signupForm h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left; 
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.createHeader{
  font-family: "Outfit", sans-serif;
  margin-top: 0;
}

.formGroup {
  margin-bottom: .5rem;
}

.formButtons{
  display: inline-flex; 
  gap: 10px;
  flex-direction: row;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.8;
}
.formGroup input[type="file"] {
  display: none;
}

.formGroup .fileInputLabel {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid rgba(173, 216, 230, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.2s ease;
  margin-top: 0.5rem;
}

.formGroup .fileInputLabel:hover {
  background-color: #333333;
  transform: translateY(-3px);
}

.formGroup .fileInputLabel:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22);
}

.formGroup .fileInputLabel:active {
  transform: translateY(2px);
  box-shadow: 0 0 10px rgba(173, 216, 230, 0.5), 0 0 20px rgba(173, 216, 230, 0.3);
}

.fileUploadIndicator {
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: #E0E0E0;
}
.formGroup input,
.formGroup select,
.formGroup textarea {
  width: 95%;
  padding: 12px;
  background-color: #161616;
  border: none;
  border-radius: 8px;
  color: #E0E0E0;
  font-size: 16px;
  font-family: 'Inconsolata', monospace;
  transition: background-color 0.3s;
}

.formGroup textarea {
  resize: vertical;
  min-height: 100px;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  background-color: #4a4a4a;
  outline: none;
}

.btnNext,
.btnSubmit {
  padding: 12px 0;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid rgba(173, 216, 230, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Inconsolata', monospace;
  width: 200px;
  transition: background-color 0.3s, transform 0.3s;
}

.btnNext:hover,
.btnSubmit:hover {
  background-color: #333333;
  transform: translateY(-3px);
}

.btnNext,
.btnSubmit {
  float: right;
  background-color: #181818;
}

.btnBack {
  float: left;
  background-color: #1e1e1e;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.error {
  color: #ff6b6b;
  margin-bottom: 1rem;
  text-align: center;
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileInputLabel {
  padding: 12px 0;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid rgba(173, 216, 230, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Inconsolata', monospace;
  width: 200px;
  transition: background-color 0.3s, transform 0.3s;
  display: inline-block;
  margin-top: 0.5rem;
  text-align: center;
}

.fileInputLabel:hover {
  background-color: #333333;
  transform: translateY(-3px);
}

.fileInputLabel:focus-within {
  outline: none;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22);
}

.fileName {
  margin-left: 1rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.btnNext:disabled,
.btnSubmit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loadingIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, #1f1f1f, #131313);
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
  font-size: 1.2em;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background: #1E1E1E;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #E0E0E0;
}

.autopopulateButton {
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid rgba(173, 216, 230, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Inconsolata', monospace;
}

.autopopulateButton:hover {
  background-color: #333333;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 15px;
}

.checkboxLabel input[type="checkbox"] {
  margin-right: 10px;
}

.termsContent {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  font-size: 0.9em;
  line-height: 1.5;
  color: #E0E0E0;
}

.modalContent {
  background-color: #1E1E1E;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 5px;
  color: #E0E0E0;
}

.closeButton {
  position: absolute;
  top: 50px;
  right: 400px;
  background-color: #e26200;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tos {
  margin-left: 10px;
  color: rgb(76, 124, 248); 
}



/* Upload Resume to Autofill button */
.uploadButton {
  font-family: "Outfit";
  display: inline-flex;
  align-items: center;
  gap: 10px; 
  padding: 15px 20px 15px 15px;
  background-color: #1e1e1e;
  color: white;
  border: 1px solid rgba(173, 216, 230, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px; /* Ensure this is applied */
  transition: background-color 0.3s, transform 0.3s;
  text-align: center; /* Center text in label */
}

.uploadButton:hover {
  background-color: #333333;
  transform: translateY(-2px);
}

.uploadButton:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22);
}

.uploadButton:active {
  transform: translateY(2px);
  box-shadow: 0 0 10px rgba(173, 216, 230, 0.5), 0 0 20px rgba(173, 216, 230, 0.3);
}
/* Fill Out Manually button */
.manualEntryButton {
  font-family: "Outfit";
  padding: 10px 15px;
  background-color: #252525;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;  /* Decreased font size */
  transition: background-color 0.3s, transform 0.3s;
}

.manualEntryButton:hover {
  background-color: #333333;
  transform: translateY(-3px);
}

.manualEntryButton:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(128, 180, 231, 0.342), 0 0 30px rgba(126, 161, 199, 0.22);
}

.manualEntryButton:active {
  transform: translateY(2px);
  box-shadow: 0 0 10px rgba(173, 216, 230, 0.5), 0 0 20px rgba(173, 216, 230, 0.3);
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {

  .signupForm{
    margin: 50px; 
    min-width: 40vw; 
    min-height: 35vh;
  }

  .formButtons{
    display: inline-flex; 
    flex-direction: column;
    padding: 20px; 
  }
  
  .formButtons svg{
    font-size: 24px; 
  }

  .createHeader{
    padding: 20px 0 0 20px; 
  }
}
