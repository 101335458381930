


.previewContainer {
    padding: 20px;
    text-align: left;
    font-family: Arial, sans-serif;
    background-color: #fff;
    color: #333;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profilePhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h3 {
    margin-bottom: 10px;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
  }
  
  .section p, .section ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .section ul li {
    margin-bottom: 5px;
  }
  
  .section a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  .section a:hover {
    text-decoration: underline;
  }
  

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profilePhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .divider {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
  }
  
