@import url("https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap"); 
@import url("https://fonts.googleapis.com/css2?family=Jersey+10&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');



/* siloDescription.module.css */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
    position: relative;
    width: 100%; /* Changed from 100vw to 100% */
    height: 100vh;
    background-color: #1a1a1a;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%; /* Changed from fixed pixels to percentage */
    box-sizing: border-box; /* Include padding in width calculation */
}

.leftContent {
    z-index: 2;
    margin-top: -35vh; 
    max-width: 50%; /* Ensure left content doesn't overflow */
}

.rightContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    width: 80%; /* Slightly wider to accommodate left shift */
    margin-top: -25vh; /* Move content up */
    padding-left: 0;
}

.testTitle {
    margin-bottom: 0px;
}

.letters{
    font-family: 'Outfit';
    TEXT-TRANSFORM: UPPERCASE;
    font-size: 10vw; /* Responsive font size */
    margin: 10px; 
}

.aboutHeader {
    font-size: 8vw; /* Responsive font size */
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 2vw; /* Responsive letter spacing */
    line-height: 1.2;
    white-space: nowrap; /* Prevent wrapping */
}

.helloLine, .worldLine {
    display: flex;
    font-family: "Jacquard 12", system-ui; 
}

.aboutHeader span {
    display: inline-block;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.headerLoaded span {
    opacity: 1;
    transform: translateY(0);
}

/* Hello animation */
.headerLoaded .helloLine span:nth-child(1) { transition-delay: 0.1s; }
.headerLoaded .helloLine span:nth-child(2) { transition-delay: 0.2s; }
.headerLoaded .helloLine span:nth-child(3) { transition-delay: 0.3s; }
.headerLoaded .helloLine span:nth-child(4) { transition-delay: 0.4s; }
.headerLoaded .helloLine span:nth-child(5) { transition-delay: 0.5s; }

/* World animation */
.headerLoaded .worldLine span:nth-child(1) { transition-delay: 0.75s; }
.headerLoaded .worldLine span:nth-child(2) { transition-delay: 0.85s; }
.headerLoaded .worldLine span:nth-child(3) { transition-delay: 0.95s; }
.headerLoaded .worldLine span:nth-child(4) { transition-delay: 1.05s; }
.headerLoaded .worldLine span:nth-child(5) { transition-delay: 1.15s; }

/* CTA Container */
.ctaContainer {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.7s ease-out 1.5s, transform 0.7s ease-out 1.5s;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ctaLoaded {
    opacity: 1;
    transform: translateX(0);
}

/* CTA Text */
.cta {
    font-family: 'Outfit';
    font-size: 60px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    padding: 10px 20px;
    background: rgba(115, 190, 255, 0.0);
    backdrop-filter: blur(0px);
    border-radius: 5px;
    width: auto;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}

.ctaLoaded .cta:nth-child(1) {
    transition-delay: 2s;
    opacity: 1;
    transform: translateX(0);
}

.ctaLoaded .cta:nth-child(2) {
    transition-delay: 3s;
    opacity: 1;
    transform: translateX(0);
}

/* Create Button */
.createButton {
    padding: 15px 30px;
    font-size: 20px;
    font-family: 'Outfit';
    font-weight: 500;
    background: linear-gradient(135deg, rgba(0, 95, 178, 0.8), rgba(0, 45, 98, 0.8));
    border: 2px solid white;
    color: #ffffffec;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.5s ease-out 2s, transform 0.5s ease-out 2s, all 0.5s ease;
    backdrop-filter: blur(5px);
    position: relative;
    overflow: hidden;
}

.buttonLoaded {
    transition-delay: 4s;
    opacity: 1;
    transform: translateX(0);
}

.createButton:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        120deg,
        transparent,
        rgba(255, 255, 255, 0.3),
        transparent
    );
    transition: all 0.5s;
}

.createButton:hover {
    background-color: #062b49;
    border: 2px solid rgba(115, 190, 255, 0.3);
    box-shadow: 0 0 20px rgba(0, 121, 226, 0.8);
    transform: scale(1.05) rotate(-1deg);
}

.createButton:hover:before {
    left: 100%;
}

.createButton:hover:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 40%;
    transform: scale(0);
    animation: ripple 0.6s linear;
}

@keyframes ripple {
    to {
        transform: scale(3);
        opacity: 0;
    }
}

/* Background animation */
.backgroundAnimation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.modal {
    position: fixed;
    top:0px;
    left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    padding: 50px;
    border-radius: 10px;
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    margin: auto;
    overflow-y: auto;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    width: 50px; 
    cursor: pointer;
  }

  .closeButton{
    position: absolute;
    top: 60px;
    left: 60px; 
    align-content: center;
    background: rgb(252, 72, 7);
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: white; 
    border-radius: 80px;
    padding: 8px; 
  }