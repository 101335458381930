@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.projectContainer {
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 12px;
  background-image: linear-gradient(
    to bottom,
    rgba(46, 46, 46, 0.4),
    rgba(0, 0, 0, 0)
  );
  color: #e0e0e0;
  border: 4px solid rgba(46, 46, 46, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border 0.3s ease;
}

.projectContainer:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border: 4px solid rgba(59, 137, 255, 0.435);
  border-radius: 10px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 5px;
}

.upvotesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
}

.upvoteItem {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  padding: 15%;
}

.upvoteButton {
  background: none;
  border: 2px solid #4a90e2;
  color: #4a90e2;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 5px 8px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.upvoteButton:hover {
  background-color: #e8f0fe;
}

.clickedUpvoteButton {
  background-color: #4a90e2;
  color: white;
  border: 2px solid #4a90e2;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 5px 8px;
}

.titleAndUsernameContainer {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  margin: 0 10px 0 0;
  overflow: hidden;
  gap: 5px;
}

.visIcon {
  color: #4a90e2;
  font-size: 1.2rem;
  display: flex;
  margin-top: 0px;
  margin-left: 10px;
}

.projectTitle {
  font-family: 'Outfit';
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  margin-right: 10px;
  margin-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.upvoteAndCommentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px;
}

.byUsername {
  font-size: 1rem;
  color: #666;
  white-space: nowrap;
}

.username {
  font-weight: 600;
  color: #4a90e2;
  cursor: pointer;
}

.tagsContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 120px;
  overflow-x: auto;
}

.tag {
  background-color: #00579fb5;
  color: #a0c8ff;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.tag:hover {
  background-color: #456090;
}

.divider {
  height: 1px;
  background-color: #9b9b9b;
  margin: 20px 0;
}

/* New styles for preview section */
.previewSection {
  cursor: pointer;
}

.previewContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

/* New styles for image container with blur effect */
.imageContainer {
  position: relative;
  max-height: 400px; /* Adjust as needed */
  border-radius: 8px;
}

.imageBackground {
  position: absolute;
  top: 0;
  left: -120%;
  width: 350%;
  height: 100%;
  background-position: center;
  background-size: cover;
  filter: blur(10px) brightness(30%);
  opacity: 0.7;
}

.previewImage {
  position: relative;
  max-height: 400px;
  max-width: 100%;
  z-index: 1;
  object-fit: contain;
  border-radius: 8px;
}

/* Styles for code preview */
.codeContainer {
  background-color: #282c34;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  min-width: 100%;
  overflow-x: auto;
}

.codePreview {
  margin: 0;
  padding: 5px;
}

.codePreview code {
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-all;
}

.descContainer {
  flex: 1;
  padding: 10px;
}

.description {
  line-height: 2;
  color: #c0c0c0;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 10px;
  margin-right: 20px;
  font-family: 'Lato';
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  border-left: 6px solid #4a90e2;
  padding-left: 15px;
  border-radius: 8px;
}

.description::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background: linear-gradient(
    to bottom,
    rgba(42, 42, 42, 0),
    rgba(30, 30, 30, 0.182)
  );
}

.seeMoreButton {
  width: 48%;
  display: inline-flex;
  background-color: #e8f0fe;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #4a90e2;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px 12px;
  transition: all 0.2s ease;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bolder;
}

.seeMoreButton:hover {
  background-color: #73a0ee;
  color: #ffffff;
}

.commentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2a2a2a;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 15px 0 15px 0;
  transition: background-color 0.2s ease;
  width: 89%;
}

.commentBox:hover {
  background-color: #575757;
}

.commentIconContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.commentIcon {
  color: #4a90e2;
  font-size: 1.2rem;
}

.commentText {
  color: #acacac;
  font-size: 1rem;
}

.expandButton {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expandButton:hover {
  color: #2a6cb0;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  transition: color 0.2s ease;
  background-color: rgb(255, 47, 0);
  border-radius: 30px;
}

.closeButton:hover {
  color: #4a90e2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .headerContainer {
    flex-wrap: wrap;
  }

  .titleAndUsernameContainer {
    flex-basis: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .projectTitle {
    white-space: normal;
    word-wrap: break-word;
  }

  .byUsername {
    margin-top: 5px;
  }

  .tagsContainer {
    flex-basis: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .previewSection {
    flex-direction: column;
  }

  .previewContainer {
    width: 100%;
    max-height: none;
  }

  .descContainer {
    width: 100%;
  }

  .popupContent {
    width: 95%;
    height: 95%;
  }
}
