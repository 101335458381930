

/* PasswordReset.module.css */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #727272;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    max-width: 35px;
    cursor: pointer;
    color: #333;
  }

  .submitEmail {
    position: relative !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  
  .submitEmail input {
    width: 100% !important;
    padding: 12px 12px 12px 40px !important;
    background-color: #3c3c3c !important;
    border: none !important;
    border-radius: 8px !important;
    color: #E0E0E0 !important;
    font-family: 'Inconsolata', monospace !important;
    font-size: 16px !important;
    transition: background-color 0.3s !important;
    box-sizing: border-box !important;
    text-align: center !important;
  }
  
  .submitEmail input::placeholder {
    color: #A2A2A2 !important;
    align-items: center; 
  }
  
  .submitEmail input:focus {
    background-color: #4a4a4a !important;
    outline: none !important;
  }

  
  .h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .message {
    color: green;
    margin-top: 10px;
  }
  
