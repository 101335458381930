/* publicProjectEntry.module.css - Dark Mode */

.projectContainer {
  padding: 20px 35px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: #1e1e1e;
  color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  max-width: 90vw;
  margin: 16px auto;
  box-shadow: 0 2px 4px rgba(245, 245, 245, 0.1); /* Add this line */
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
}

.titleAndUsernameContainer {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  margin: 0;
  overflow: hidden;
}

.projectTitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 25px 0 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #70b9e3;
}

.byUsername {
  font-size: 1.1rem;
  color: #bbb;
  white-space: nowrap;
}

.username {
  font-weight: 600;
  color: #70b9e3;
}

.tagsContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 120px;
}

.tag {
  background-color: #2c2c2c;
  color: #70b9e3;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: .8rem;
  font-weight: 500;
  margin-top: 5px; 
  margin-bottom: 5px;
  transition: background-color 0.2s ease;
}

.tag:hover {
  background-color: #3a3a3a;
}

.projectDescriptionContainer {
  margin-top: 16px;
  margin-bottom: 20px;
  position: relative;
}

.projDescription {
  font-size: 1rem;
  color: #ddd;
  line-height: 1.5;
}

.collapsedDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMoreButton {
  background-color: #2c2c2c;
  border: none;
  color: #70b9e3;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.seeMoreButton:hover {
  background-color: #70b9e3;
  color: #1e1e1e;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #444;
  margin: 20px 0;
}

.layerDisplayContainer {
  margin-top: 16px;
}

.buttonContainer {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.linkButton {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background-color: #2c2c2c;
  color: #70b9e3;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 8px;
}

.linkButton:hover {
  background-color: #70b9e3;
  color: #1e1e1e;
}

.linkButton span {
  margin-left: 8px;
}

/* Orange accent */
.linkButton:nth-child(3n) {
  color: #ffa500;
}

.linkButton:nth-child(3n):hover {
  background-color: #ffa500;
  color: #1e1e1e;
}