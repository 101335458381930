@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'minionpro-medium';
  src: url('../../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.container {
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.headerPaper {
  background-color: #202020 !important;
  padding: 40px;
  margin-bottom: 30px;
  border-radius: 15px !important;
}

.titleTop {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.viewToggle {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.waitingContainer {
  font-weight: bolder; 
  display: flex;
  gap: 10px; 
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.518);
  font-size: 2rem;
}

.toggleButton {
  margin: 0 10px !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  transition: all 0.3s ease !important;
  min-width: 150px !important;
}

.toggleButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.activeToggle {
  background-color: #4fc3f7 !important;
  color: #121212 !important;
}

.searchContainer {
  margin-bottom: 20px;
}

.searchInputWrapper {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  border: 1px solid #4fc2f775;
  border-radius: 12px;
  padding: 8px 16px;
}

.searchInput {
  background-color: transparent;
  border: none;
  color: #ffffff !important;
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  outline: none;
}

.searchIcon {
  color: #9e9e9e;
  margin-right: 10px;
  font-size: 1.2rem;
}

.jobGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 30px;
}

.jobItem {
  display: flex;
  height: 100%;
}

.loadingContainer,
.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  text-align: center;
}

.errorContainer {
  color: #f44336;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4fc3f7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.jobCount {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.noJobsFound {
  grid-column: 1 / -1;
  text-align: center;
  color: #9e9e9e;
  font-size: 1.2rem;
  padding: 40px 0;
}

.sliderContainer {
  display: flex;
  position: relative;
  background-color: #3f3f3f;
  border-radius: 30px;
  margin-bottom: 24px;
  overflow: hidden;
  border: 1px solid #00a0e9;
}

.sliderOption {
  flex: 1;
  text-align: center;
  padding: 15px 0;
  color: #9e9e9e;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 1;
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

.sliderOption:hover {
  color: #e1e1e1;
}

.activeSlider {
  color: #e1e1e1;
}

.header{
  font-family: 'Outfit';

  letter-spacing: 10px;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  color:#f0f0f0e4;
  text-shadow: 0 0 10px rgba(225, 225, 225, 0.185);
  position: relative;
  overflow: hidden;
  }
  
  .header::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 25%,
      transparent 50%
    );
    transform: rotate(45deg);
    pointer-events: none;
    z-index: 1;
    animation: shimmer 3s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-100%) rotate(45deg);
    }
    100% {
      transform: translateX(100%) rotate(45deg);
    }
  }
  
  .header::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, transparent, #00a0e9, transparent);
  }
  

.subheader{
  font-family: "Outfit";
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.819); 
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(135deg, #0074ae, #00486c);
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 25, 35, 0.588);
  transition: transform 0.3s ease;
}

.sliderRight {
  transform: translateX(100%);
}

@media (max-width: 768px) {
  .jobGrid {
    grid-template-columns: 1fr;
  }

  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .headerPaper {
    padding: 16px;
  }

  .titleTop {
    font-size: 1.5rem;
  }

  .toggleButton {
    min-width: 120px !important;
    font-size: 0.9rem !important;
  }

  .sliderOption {
    font-size: 1.2rem !important;
  }
}