@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  border-radius: 8px; 
  flex-direction: column;
}

.projectDirectory {
  flex: 1;
  border-radius: 8px;
  overflow-y: auto; /* Enable vertical scrolling if needed */
  padding: 0px 20px 20px 20px;
}

.projectsTitle {
  font-family: 'Outfit';
  position: sticky;
  border-left: 4px solid transparent;
  border-image: linear-gradient(to bottom, rgba(63, 162, 254, 0.8), rgba(63, 164, 254, 0.0)) 1;
  border-radius: 80px;
  border-image-slice: 1;
  border-image-outset: 1px;  top: 0;
  z-index: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-image: linear-gradient(to bottom, rgba(46, 46, 46, 0.4), rgba(30, 30, 30, 0.0)); 
  padding: 20px;
  color: white; 
  font-size: 20px;
  border-radius: 8px;
  letter-spacing: 5px;
  margin-top: 0px;
  font-weight: 200;
}


