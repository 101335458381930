

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 5px 0;
  z-index: 1000; /* Add this line */
}

.popupContent {
  padding: 5px 20px 20px 20px;
  border-radius: 5px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
  z-index: 1001; /* Add this line */
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 30px;
  background: none;
  color: white; 
  border: none;
  font-size: 20px;
  cursor: pointer;
  background-color: #438edf;
  border-radius: 100px;
}

.projectContainer {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #4a4a4a;
  border-radius: 12px;
  background-color: #252525;
  color: #e0e0e0;
  border: 4px solid rgba(59, 137, 255, 0); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  transition: border 0.3s ease;
  width: 94%; 
}

.projectContainer:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border: 4px solid rgba(59, 137, 255, 0.7); 
  border-radius: 10px;
  transition: border 0.3s ease;
}

.descAndPreviewContainer:hover{
  cursor: pointer;
}


.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 5px;
}

.titleAndUsernameContainer {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  margin: 0 10px;
  overflow: hidden;
}

.projectTitle {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
}

.byUsername {
  font-size: 1.0rem;
  color: #b0b0b0;
  white-space: nowrap;
}

.tagsContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 120px;
  overflow-x: auto;
}

.tag {
  background-color: #4a4a4a;
  color: #a0c8ff;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: .9rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.tag:hover {
  background-color: #5a5a5a;
}

.divider {
  height: 1px;
  background-color: #4a4a4a;
  margin: 15px 0;
}

.descAndPreviewContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  min-height: 200px;
  max-height: 250px;
}

.descContainer {
  flex: 1;
  width: 50%;
  position: relative;
  overflow: hidden;
}

.description {
  line-height: 2;
  color: #c0c0c0;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 20px;
  margin-right: 40px;
  font-family: 'Lato';
}

.description::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(50, 50, 50, 0), rgb(38, 38, 38));
}

.previewText::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, rgba(50, 50, 50, 0), rgb(38, 38, 38));
}

.previewContainer {
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.previewText {
  line-height: 2;
  line-height: 2;
  color: #c0c0c0;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Lato';
  font-size: 16px;
  margin-top: 0px; 
}

.seeMoreButton {
  width: 48%;
  display: inline-flex;
  background-color: #4a4a4a;
  border: 1px solid #5a5a5a;
  border-radius: 4px;
  color: #a0c8ff;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px 12px;
  transition: all 0.2s ease;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bolder;
}

.seeMoreButton:hover {
  background-color: #5a5a5a;
  color: #ffffff;
}


.moreButton {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .descAndPreviewContainer {
    flex-direction: column;
    height: auto;
    max-height: none;
  }

  .descContainer,
  .previewContainer {
    width: 100%;
  }

  .descContainer {
    height: 150px;
  }

  .previewContainer {
    max-height: 300px;
  }
}
  
  