body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #1E1E1E;
  font-family: 'Arial', sans-serif;
}

.centralContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: auto;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  background-color: rgba(45, 45, 45, 0.3) !important;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 11, 31, 0.4);
  max-width: 600px;
  margin: 0 auto; /* Add this line to center the div horizontally */
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #E0E0E0;
  margin-top: 0px;
}

.container {
  background-color: #2D2D2D;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #E0E0E0;
  margin-top: 0px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  margin-bottom: 1rem;
}

.inputText {
  width: 95%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #3C3C3C;
  color: #E0E0E0;
  font-size: 1rem;
}

.inputText::placeholder {
  color: #A2A2A2;
}

.button {
  background-color: #6da6e7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #3A7BCE;
}

.backButton {
  background-color: transparent;
  border: 2px solid #4A90E2;
  color: #4A90E2;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.backButton:hover {
  background-color: #4A90E2;
  color: white;
}

.errorText {
  color: #FF6B6B;
  margin-top: 0.5rem;
}

.successText {
  color: #4CAF50;
  margin-top: 0.5rem;
}