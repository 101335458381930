@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.container {
  min-height: 100vh;
  background: #000;
  color: white;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  position: relative;
}

.splineContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1; /* Ensure it's behind the content */
  pointer-events: none; /* Allow clicks to pass through */
}

.splineViewer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  position: relative;
  z-index: 2; /* Above the spline background */
  min-height: 100vh;
  padding: 3rem 1rem;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for better readability */
}

/* Landing Page Styles */
.landingContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.heroSection {
  text-align: center;
  margin-bottom: 4rem;
}

.heroSection h1 {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.heroSection p {
  font-size: 1.25rem;
  color: #94a3b8;
  max-width: 36rem;
  margin: 0 auto;
}

.uploadSection {
  max-width: 32rem;
  margin: 0 auto 4rem;
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(8px);
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

.fileInputWrapper {
  margin-bottom: 1.5rem;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border: 2px dashed rgba(148, 163, 184, 0.4);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(17, 24, 39, 0.5);
}

.fileInputLabel:hover {
  border-color: #3b82f6;
  background: rgba(17, 24, 39, 0.7);
}

.fileInputContent {
  text-align: center;
}

.uploadIcon {
  width: 2rem;
  height: 2rem;
  color: #94a3b8;
  margin-bottom: 1rem;
}

.fileInputLabel span {
  color: #e5e7eb;
  font-size: 1.125rem;
}

.dropText {
  font-size: 0.875rem;
  color: #94a3b8;
  margin-top: 0.5rem;
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submitButton:hover:not(:disabled) {
  background: #2563eb;
}

.disabledButton {
  background: rgba(148, 163, 184, 0.2);
  cursor: not-allowed;
}

.loadingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.spinner {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.stepsSection {
  margin-bottom: 4rem;
}

.stepsSection h2 {
  text-align: center;
  font-size: 2rem;
  color: white;
  margin-bottom: 2rem;
}

.stepsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.stepCard {
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(8px);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.stepCardTitle {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.stepCardDescription {
  color: #94a3b8;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.featureCard {
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(8px);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.featureIcon {
  width: 3rem;
  height: 3rem;
  background: rgba(59, 130, 246, 0.2);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.featureIcon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #3b82f6;
}

.featureCard h3 {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.featureCard p {
  color: #94a3b8;
}

.testimonialSection {
  max-width: 48rem;
  margin: 0 auto;
}

.testimonialSection blockquote {
  background: rgba(17, 24, 39, 0.7);
  backdrop-filter: blur(8px);
  padding: 2rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.testimonialSection p {
  color: white;
  font-size: 1.25rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonialSection footer {
  color: #94a3b8;
}

.resultsContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(30, 30, 30, 0.9); /* Increased opacity for better visibility */
  border-radius: 12px;
  color: #ffffff;
}

.resultsContainer .results h2 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.resultItem {
  margin-bottom: 30px;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
}

.matchCount {
  font-size: 0.9em;
  color: #4a90e2;
  margin-left: 10px;
}

.matches {
  margin-top: 15px;
}

.match {
  background-color: #1e1e1e;
  border: 1px solid #3a3a3a;
  padding: 15px;
  margin: 10px 0;
  border-radius: 6px;
}

.match h4 {
  color: #4a90e2;
  margin: 0 0 10px 0;
}

.match p {
  margin: 5px 0;
  color: #ffffff;
}

.filePath {
  color: #4a90e2;
  font-family: monospace;
}

.analysis {
  margin-top: 40px;
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  border: 1px solid #3a3a3a;
}

.skillAnalysis {
  margin: 15px 0;
  padding: 15px;
  background-color: #1e1e1e;
  border-radius: 6px;
}

.skillAnalysis h4 {
  color: #4a90e2;
  margin-bottom: 10px;
}

.strengthIndicator {
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;
}

.excellentmatch {
  background-color: #155724;
  color: #d4edda;
}

.strongmatch {
  background-color: #28a745;
  color: #ffffff;
}

.moderatematch {
  background-color: #ffc107;
  color: #212529;
}

.limitedmatch {
  background-color: #dc3545;
  color: #ffffff;
}

.matchStats {
  margin-left: 10px;
  font-weight: normal;
  font-size: 0.9em;
  opacity: 0.8;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: #2c2c2c;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
}

.progressFill {
  height: 100%;
  background-color: #4a90e2;
  transition: width 0.3s ease;
}

.candidateHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.candidateInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.candidateLinks {
  display: flex;
  gap: 12px;
}

.candidateLinks a {
  color: #4a90e2;
  transition: color 0.3s ease;
}

.candidateLinks a:hover {
  color: #357abd;
}

.topSkills {
  margin-top: 20px;
}

.skillsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: #9ca3af;
}

.skillsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.skillBadge {
  padding: 4px 12px;
  background-color: #2c2c2c;
  border-radius: 16px;
  font-size: 14px;
}

.skillButton {
  padding: 6px 12px;
  background-color: #2c2c2c;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.skillButton:hover {
  background-color: #3a3a3a;
}

.skillButton.selected {
  background-color: #4a90e2;
  border-color: #4a90e2;
}

.expandButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  margin-top: 16px;
  background-color: transparent;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  color: #9ca3af;
  cursor: pointer;
  transition: all 0.3s ease;
}

.expandButton:hover {
  background-color: #2c2c2c;
  color: #ffffff;
}

.skillsSearched {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.matchHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  .heroSection h1 {
    font-size: 2.5rem;
  }

  .stepsGrid,
  .featuresGrid {
    grid-template-columns: 1fr;
  }
}
