@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Gruppo&family=Jacquard+24&family=Jacquarda+Bastarda+9&family=Jersey+10&family=Silkscreen:wght@400;700&display=swap');

.feedContainer {
  padding: 1rem 2rem;
  font-family: 'Inconsolata', monospace;
  color: #e0e0e0;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 100vw;
  margin: 0;
  overflow-x: hidden;
}

/* Sexy Toggle Switch Styles */
.viewToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  font-family: 'Exo 2', sans-serif;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2d2d2d;
  transition: .4s;
  border-radius: 34px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #7faacb;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

input:checked + .slider {
  background-color: #4a4a4a;
}

input:checked + .slider:before {
  transform: translateX(26px);
  background-color: #ff7b00;
}

.slider:hover:before {
  box-shadow: 0 0 8px rgba(127, 170, 203, 0.8);
}

input:checked + .slider:hover:before {
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.8);
}

.activeView {
  color: #ff7700;
  font-weight: bold;
  transition: color 0.3s ease;
}

input:checked ~ .activeView {
  color: #ffd700;
}

.viewLabel {
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.title {
  font-size: 3rem;
  margin: 0.5rem 0 1rem;
  color: #7faacb;
  text-align: left;
  font-family: 'Exo 2';
  font-weight: light;
  letter-spacing: 20px;
  margin-bottom: 1rem;
}

.searchSection {
  width: 100%;
}

.searchBar {
  display: flex;
  align-items: center;
  background-color: #202020;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.searchIcon {
  color: #7faacb;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.searchInput {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #e0e0e0;
  font-size: 1rem;
  padding: 0.5rem;
  min-width: 0;
}

.searchInput::placeholder {
  color: #6a6969;
}

.searchButton {
  background-color: #37a4f7c2;
  color: #eeeeee;
  border: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
  margin-left: 0.5rem;
}

.searchButton:hover {
  background-color: #6b91b0;
}

.resultsCount {
  font-size: 0.9rem;
  color: #a7a7a7;
  text-align: left;
  margin-bottom: 1rem;
}

.userList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
}

.userCard {
  background-color: #1f1f1f;
  border: 1px solid rgba(195, 195, 195, 0.515); 
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.userCard:hover {
  box-shadow: 0 0 10px rgba(196, 196, 196, 0.25);
}

.userHeader {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.userMainInfo {
  margin-left: 1rem;
  flex-grow: 1;
  overflow: hidden;
}

.username {
  font-size: 1.5rem;
  margin: 10px 0 0 0;
  color: #d3d3d3;
  word-wrap: break-word;
  line-height: 1.2;
  max-width: 100%;
}

.userType {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #a7a7a7;
  margin: 0.25rem 0;
}

.userType svg {
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.userDetails {
  border-top: 2px solid #434343;
  margin-bottom: .5rem;
  margin-top: 10px; 
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: rgb(217, 217, 217); 
  padding: 10px; 
}

.userDetails p {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  word-break: break-word;
}

.userDetails svg {
  margin-right: 0.5rem;
  color: #7faacb;
  flex-shrink: 0;
  margin-top: 0.2rem;
}

.score{
  color: #f0efef !important;
  border-radius: 30px;
  font-style: bolder !important;
  font-size: 1.1rem !important;
}

.scoreIcon{
  color: rgb(255, 106, 0) !important; 
}

.viewProfileButton {
  width: 100%;
  background-color: #0a95ff9e;
  font-weight: bolder;
  color: #e3e3e3;
  border: none;
  border-radius: 12px;
  padding: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.viewProfileButton:hover {
  background-color: #6b91b0;
}

.loadingSpinner {
  border: 4px solid #2d2d2d;
  border-top: 4px solid #7faacb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 2rem auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorMessage {
  color: #ff6b6b;
  text-align: center;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .feedContainer {
    padding: 1rem;
  }

  .userList {
    grid-template-columns: 1fr;
  }
}