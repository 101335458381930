@font-face {
  font-family: 'minionpro-medium';
  src: url('../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.leaderboardContainer {
    background-color: #1a1a1a;
    color: #e0e0e0;
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 10px;
    font-family: 'Exo 2', sans-serif;
    box-shadow: 0 0 20px rgba(0, 128, 255, 0.1);
  }
  
  .leaderboardTitle {
    font-family: 'minionpro-medium';
    font-size: 2.5rem;
    color: #e2e2e2;
    text-align: left;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: 5px;
    text-shadow: 0 0 10px rgba(14, 235, 255, 0.377);
  }
  
  .trophyIcon {
    margin-right: 1rem;
    color: #ff7700;
  }
  
  .leaderboardList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .leaderboardItem {
    display: flex;
    align-items: center;
    background-color: #222222;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .leaderboardItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 128, 255, 0.2);
  }
  
  .top1 { background: linear-gradient(45deg, #2a2a2a, #333333); border: 1px solid #ff7700; }
  .top2 { background: linear-gradient(45deg, #252525, #2d2d2d); border: 1px solid #3cc4fa; }
  .top3 { background: linear-gradient(45deg, #202020, #282828); border: 1px solid #3cc4fa; }
  
  .rank {
    font-size: 1.5rem;
    font-weight: bold;
    min-width: 40px;
    text-align: center;
    color: #3cc4fa;
    text-shadow: 0 0 5px rgba(60, 196, 250, 0.5);
  }
  
  .userInfoWrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .userInfo {
    margin-left: 1rem;
  }
  
  .username {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  
  .userType {
    font-size: 0.9rem;
    color: #3cc4fa;
    margin-left: 0.5rem;
    text-transform: uppercase;
  }
  
  .score {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #ff7700;
    margin-left: auto;
    padding-right: 1rem;
  }
  
  .scoreIcon {
    margin-right: 0.5rem;
  }
  
  .glowBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #3cc4fa, transparent);
    animation: glow 1.5s ease-in-out infinite;
  }
  
  @keyframes glow {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
  }
  
  @media (max-width: 768px) {
    .leaderboardItem {
      flex-wrap: wrap;
    }
  
    .userInfoWrapper {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  
    .score {
      width: 100%;
      justify-content: flex-end;
      padding-right: 0;
    }
  }