
.textContainer {
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

.headerContainer {
    background-color: #f2f2f2;
    padding: 10px;
    margin-bottom: 10px;
}