@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.graphContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #121212;
}

.sidebar {
  position: relative;
  width: 30%;
  height: 100vh;
  background-color: #1A1A1A;
  color: #cdd0d4;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: transform 0.3s ease;
}

.sidebarVisible {
  transform: translateX(0);
}

.sidebarHidden {
  transform: translateX(100%);
}

.graphArea {
  position: relative;
  height: 100vh;
  z-index: 1;
  transition: width 0.3s ease;
}

.toolbar {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1A1A1A;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #2A2A2A;
}

.toolbar button {
  background: none;
  border: none;
  color: #cdd0d4;
  font-size: 1.2rem;
  cursor: pointer;
}

.toolbar button:hover {
  color: #00A3FF;
}

.searchBar {
  padding: 1rem;
  background-color: #1A1A1A;
  border-bottom: 1px solid #2A2A2A;
}

.searchBar input {
  width: 100%;
  padding: 0.5rem;
  background-color: #2A2A2A;
  border: none;
  border-radius: 0.5rem;
  color: #cdd0d4;
}

.searchBar input::placeholder {
  color: #888;
}

.nodeInfo {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.nodeInfo h2,
.nodeInfo h3 {
  font-family: 'Outfit', sans-serif;
  color: #00A3FF;
  margin-top: 0px;
  margin-bottom: 0px;
}

.sidebarHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nodeDetails {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.infoLabel {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #2A2A2A;
  border-radius: 0.5rem;

}

.infoLabel p {
  font-family: "outfit";
  font-weight: 400;
  font-size: 1rem;
  padding: 5px; 
}

.labelTitle {
  font-family: 'Outfit';
  font-weight: 600;
  color: #00A3FF;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.nodeList {
  list-style: none;
  padding: 0;
}

.nodeList li {
  padding: 0.6rem;
  background-color: #2A2A2A;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 5px;
}

.nodeList li:hover {
  background-color: #3A3A3A;
}

.connectedNodes h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.1rem;
  color: #E2E8F0;
  margin-bottom: 0.5rem;
}

.header {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
  padding: 1rem;
  background-color: rgba(30, 30, 30, 0.8);
  border-radius: 0.75rem;
  color: #E2E8F0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.headerTitle {
  font-family: 'Outfit', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 0.5rem;
  color: #00A3FF;
  text-align: left;
}

.headerDescription {
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
  color: #E2E8F0;
  margin-bottom: 0.5rem;
}

.toggleLabel {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #cdd0d4;
  cursor: pointer;
}

.toggleLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.toggleSwitch {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #888;
  border-radius: 20px;
  margin-right: 0.5rem;
  transition: background-color 0.2s;
}

.toggleSwitch::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  top: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggleLabel input:checked + .toggleSwitch {
  background-color: #00A3FF;
}

.toggleLabel input:checked + .toggleSwitch::after {
  transform: translateX(20px);
}

.closeSidebarButton {
  background: none;
  border: none;
  color: #cdd0d4;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: auto;
}

.closeSidebarButton:hover {
  color: #00A3FF;
}

.sidebarToggleButton {
  position: absolute;
  top: 6rem;
  right: 1rem;
  z-index: 3;
  background: rgba(30, 30, 30, 0.8);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #cdd0d4;
  cursor: pointer;
}

.sidebarToggleButton:hover {
  color: #00A3FF;
}

.infoLabel p {
  margin: 0;
}

.pdfButton {
  background-color: #00A3FF;
  color: #1e1e1e;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 163, 255, 0.5);
}

.pdfButton:hover {
  background-color: #1e1e1e;
  color: #00A3FF;
  border: 2px solid #00A3FF;
  box-shadow: 0 0 20px rgba(0, 163, 255, 0.8);
  transform: scale(1.05);
}

.pdfButton:active {
  transform: scale(0.95);
}
