.groupDisplayContainer {
  color: #e0e0e0;
  margin: 10px 0;
  margin-left: 10px; 
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.groupHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.groupTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 10px;
}

.groupTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.groupMetadata {
  display: flex;
  gap: 15px;
  font-size: 14px;
  color: #b0b0b0;
}

.groupMetadataItem {
  display: flex;
  align-items: center;
  gap: 5px;
}

.groupDescription {
  font-size: 14px;
  color: #b0b0b0;
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.icon {
  color: #60a7ff;
  font-size: 16px;
}

.groupButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.groupButton,.addGroupButton,
.createGroupButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}
.createGroupButton{
  background-color: #60a7ff;
}
.addGroupButton{
  background-color: #cb530cfd;
}


.groupButton:hover,
.createGroupButton:hover,.addGroupButton:hover {
  background-color: #4a4a4a;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.addGroupButton:hover{
  background-color: #ff830f;
}


.createGroupButton:hover{
  background-color: #37559b;
}

.createGroupButton {
  margin-left: auto;
}

.membersList {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 12px;
  max-height: 150px;
  overflow-y: auto;
}

.memberItem {
  font-size: 14px;
  padding: 5px 0;
  color: #d0d0d0;
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.noGroup {
  color: #b0b0b0;
  font-size: 16px;
  text-align: center;
  padding: 20px;
}