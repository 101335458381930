@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
}

.layer {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.textContainer{
  display: flex;
  flex-direction: column;
}

.textHeader{
  font-family: 'Montserrat'; 
  font-weight: 500; 
  font-size: 24px;
  color: #f4f4f4;
  padding: 1.5rem 1.5rem 0 1.5rem; 
}

.textHeader:empty{
  padding: 0; 
}

.column {
  flex: 1 1 300px;
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.column:hover {
  transform: translateY(-1px);
}

.text, .editableText {
  font-family: 'Outfit'; 
  font-weight: 300; 
  font-size: 20px;
  line-height: 1.6;
  color: #e8e8e8;
  padding: 1.5rem;
  white-space: pre-wrap;
  flex-grow: 1;
  background-color: #1e1e1e; 
  text-align: justify;
  margin-left:5px; 
  margin-right:20px;
}

.editableText:focus {
  outline: 2px solid #70b9e3;
  border-radius: 4px;
}

.imageContainer {
  width: 100%;
  max-height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #1e1e1e;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.image {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  object-fit: contain;
  transition: transform 0.3s ease;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.expandIcon {
  color: white;
  font-size: 24px;
}

.imageContainer:hover .imageOverlay {
  opacity: .2;
}

.videoContainer, .pdfContainer {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
}

.video, .pdf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editInput {
  font-size: 16px;
  line-height: 1.5;
  padding: 0.75rem;
  margin: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: calc(100% - 1rem);
  transition: border-color 0.3s ease;
}

.editInput:focus {
  outline: none;
  border-color: #70b9e3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  padding: 0px 0px 20px 0px;
  border-radius: 8px;
  max-height: 80%;
  overflow: auto;
  width: 90%; 
}

.modalImage {
  max-width: 100%;
  max-height: calc(80vh - 40px);
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fa2100;
  border-radius: 50px;
  border: #333;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

/* Updated styles for code display */
.codeContainer {
  background-color: #282c34;
  border-radius: 8px;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 400px; /* Set max height */
}

.codeContent {
  display: flex;
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
  max-height: 100%; /* Allow content to scroll within container */
}

.lineNumbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.4rem 10px 0.5rem 0;
  color: #d3d3d3;
  user-select: none;
  text-align: right;
  min-width: 25px; /* Ensure enough space for line numbers */
  gap: 1.16px; 
  margin-left: 2px;
}

.lineNumbers span {
  height: 20px;
  line-height: 20px;
}

.code {
  flex: 1;
  padding: 0.5rem 0 0.5rem 10px;
  margin: 0;
  overflow: visible; /* Allow horizontal scrolling */
  background-color: transparent;
}

.code code {
  display: block;
  min-width: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  white-space: pre;
}

/* Override highlight.js styles */
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0;
  background-color: transparent !important;
  color: #333;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .layer {
    flex-direction: column;
  }

  .column {
    flex-basis: 100%;
  }

  .imageContainer {
    height: 250px;
  }

  .modalContent {
    max-width: 90%;
    max-height: 90%;
  }

  .modalImage {
    max-height: calc(90vh - 40px);
  }
}
::-webkit-scrollbar-track {
  background: #dbdbdb;
}