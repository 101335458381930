.boxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #1e1e1e;
  color: #D9D9D9;
  font-family: 'Arial', sans-serif;
}

.profileEditorContainer {
  padding: 20px;
  width: 100%;
  background-color: #2d2d2d;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profileEditorTitle {
  text-align: center;
  margin-bottom: 10px;
  color: #85bcdb;
  font-size: 24px;
}

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  background-color: #6A6969;
  color: #D9D9D9;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.button:hover {
  background-color: #85bcdb;
  color: #1e1e1e;
}

.activeTab {
  background-color: #6ca3c0;
  color: #1e1e1e;
}

.form {
  background-color: #2d2d2d;
  padding: 20px;
  border-radius: 8px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input, .textarea {
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #6A6969;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #1e1e1e;
  color: #D9D9D9;
}

.textarea {
  resize: vertical;
  min-height: 100px;
}

.addOrSubButton {
  background-color: #A2A2A2;
  color: #1e1e1e;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: block;
  margin: 10px auto;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.addOrSubButton:hover {
  background-color: #85bcdb;
}

.addForm {
  padding: 20px;
  background-color: #2d2d2d;
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
  margin-bottom: 20px;
}

.saveButton, .autofillButton {
  background-color: #6ca3c0;
  color: #1e1e1e;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.saveButton:hover, .autofillButton:hover {
  background-color: #85bcdb;
}

.fieldContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
}

.toggle {
  background-color: #A2A2A2;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.toggleActive {
  background-color: #6ca3c0;
}

.inputDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.currentInfo {
  background-color: #2d2d2d;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 45%;
  max-height: 100%;
  overflow-y: auto;
}

.currentInfoTitle {
  text-align: left;
  margin-bottom: 20px;
  color: #85bcdb;
  font-size: 24px;
  border-bottom: 1px solid #6ca3c0;
  padding-bottom: 10px;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.infoItem {
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 5px;
}

.infoLabel {
  font-weight: bold;
  color: #6ca3c0;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.infoValue {
  color: #D9D9D9;
  font-size: 14px;
  word-break: break-word;
}

.fullWidth {
  grid-column: 1 / -1;
}

.errorMessage {
  color: #ff6b6b;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .infoGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .boxContainer {
    flex-direction: column;
  }

  .profileEditorContainer,
  .currentInfo {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}