@font-face {
  font-family: 'minionpro-medium';
  src: url('../../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.card {
    background-color: #222222;
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }
  
  .cardContent {
    padding: 30px 30px 30px 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-family: 'minionpro-medium';
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bolder;
    margin-bottom: 6px;
    letter-spacing: 2px;
    text-align: left;
  }
  
  .company {
    color: #4fc3f7;
    font-size: 1.1rem;
    margin-bottom: 4px;
    font-weight: 600;
  }
  
  .location {
    color: #9e9e9e;
    font-size: 1.1rem;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }
  
  .location::before {
    content: '\f3c5';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 8px;
    font-size: 0.9rem;
  }
  
  .description {
    font-family: 'minionpro-medium';
    color: #e0e0e0;
    font-size: 1.1rem;
    line-height: 2;
    margin-bottom: 10px;
    flex-grow: 1;
  }
  
  .applyButton {
    font-family: 'Outfit';
    display: inline-block;
    background-color: #10a0e2;
    color: #ffffff;
    font-size: 14px; 
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    text-decoration: none;
    text-align: center;
    align-self: flex-start;
  }
  
  .applyButton:hover {
    background-color: #0081bc;
    transform: scale(1.05);
  }