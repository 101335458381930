@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.container {
  min-height: 100vh;
  background: #000;
  color: white;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  position: relative;
}

.splineContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 10;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.logo img {
  height: 2rem;
}

.logo span {
  font-size: 1.5rem;
  font-weight: 600;
  background: linear-gradient(to right, #fff, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 2rem;
}

.heroContent {
  text-align: center;
  max-width: 900px;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 1.5rem;
  border: 1px solid rgba(71, 169, 255, 0.1);
}

.badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(71, 169, 255, 0.1);
  border-radius: 9999px;
  margin-bottom: 2rem;
  color: #47a9ff;
  font-weight: 500;
}

.badgeIcon {
  width: 1rem;
  height: 1rem;
}

.title {
  font-size: clamp(2.5rem, 8vw, 4.5rem);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.highlight {
  display: block;
  color: #47a9ff;
}

.subtitle {
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  color: #999;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.features {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ccc;
}

.featureIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: #47a9ff;
}

.ctaButton {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  background: #47a9ff;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(71, 169, 255, 0.4);
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.scrollIndicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  cursor: pointer;
  color: #47a9ff;
}

@keyframes bounce {
  50% { transform: translate(-50%, -10px); }
}

.detailedFeatures {
  position: relative;
  padding: 6rem 2rem;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.featureCard {
  padding: 2.5rem;
  background: rgba(71, 169, 255, 0.05);
  border: 1px solid rgba(71, 169, 255, 0.1);
  border-radius: 1rem;
  transition: transform 0.2s, border-color 0.2s;
}

.featureCard:hover {
  transform: translateY(-5px);
  border-color: rgba(71, 169, 255, 0.3);
}

.featureIcon {
  width: 2.5rem;
  height: 2.5rem;
  color: #47a9ff;
  margin-bottom: 1.5rem;
}

.featureCard h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.featureList {
  margin-bottom: 1.5rem;
}

.featureList p {
  margin-bottom: 0.75rem;
  color: #ccc;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.featureDescription {
  color: #999;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .detailedFeatures {
    padding: 4rem 1rem;
  }
  
  .featureGrid {
    grid-template-columns: 1fr;
  }
  
  .features {
    gap: 1rem;
  }
  
  .heroContent {
    padding: 1.5rem;
  }
}

/* Previous CSS remains the same, just add these new styles: */

.viewToggle {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.75rem 1.5rem;
  background: rgba(71, 169, 255, 0.1);
  border: 1px solid rgba(71, 169, 255, 0.3);
  border-radius: 9999px;
  color: #47a9ff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  z-index: 100;
}

.viewToggle:hover {
  background: rgba(71, 169, 255, 0.2);
  transform: translateY(-50%) scale(1.05);
}

/* Update header styles to accommodate the new button */
.header {
  display: flex;
  justify-content: center; /* Center the logo */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 10;
}

/* Add transition for smooth content changes */
.heroContent,
.featureCard {
  transition: opacity 0.3s ease;
}

/* Animation for view switching */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.heroContent {
  animation: fadeIn 0.5s ease;
}