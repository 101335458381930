

* {
    font-family: inconsolata; 
  }
  
  .profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  
  .profileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    background-color:#D9D9D9;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .profileHeader > div {
    flex: 1;
    margin: 0 10px;
  }
  
  .weblink {
    color: #1e1e1e;
    text-decoration: none;
  }
  
  .buttonContainer{ 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: calc(99% - 50px);
    margin-right: auto;
    margin-left: auto;
  }

  .copyConfirmation {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .button {
    font-family: inconsolata;
    margin-bottom: 10px;
    margin-right: 140px;
    padding: 8px 16px;
    background-color: #b9d9eb;
    color: #1e1e1e;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 60px;
    width: 140px;
    font-size: 15px;
  }
  .bigButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to left, #e4e4e4, #d6d6d6);
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: calc(100% - 80px); /* Reduced width by increasing subtraction */
    max-width: 600px; /* Added max-width for larger screens */
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: 'inconsolata';
    font-weight: bolder;
    font-size: 18px;
    color: #1e1e1e;
    border: none;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .bigButton:hover {
    background: linear-gradient(to left, #e8e8e8, #dedede);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .bigButton:focus {
    outline: none;
  }
  
  .bigButton:active {
    transform: translateY(1px);
  }

  .bigButtonAdd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to left, #232323, #191919);
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: 'inconsolata';
    font-weight: bolder;
    font-size: 18px;
    color: #ececec;
    border: none; 
    transition: background 0.3s ease, box-shadow 0.3s ease;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.315);
    border-bottom: rgba(1, 98, 226, 0.388) .5px solid;
  }

  .bigButtonAdd > * {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
  }

  .bigButtonAdd:hover {
    background: linear-gradient(to left, #171717, #1f1f1f);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.204);
    color: #ffffff;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.517);
    cursor: pointer; 
  }

  .bigButtonAdd:focus {
    outline: none;
  }

  .bigButtonAdd:active {
    transform: translateY(1px);
  }

  .button:hover {
    background-color: #7FAACB;
  }
  
  .container {
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .profileDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0;
  }
  
  .detailsColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0px;
    margin-right: 20px;
    margin-left: 250px;
    width: 100%;
  }
  
  .detailEntry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0px;
    background-color: #B9D9EB;
    width: 100%;
  }
  
  .imageAndEmail { 
    display: 'flex';
    flex-Direction: 'column'; 
    align-Items: 'center';
    margin-Bottom: '0px';
    margin-right: 250px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background-color: #131313;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 90%; /* Increase initial width */
    max-width: 2000px; /* Increase maximum width */
    max-height: 90%;
    overflow-y: auto;
  }
  
  @media (min-width: 768px) {
    .modalContent {
      width: 90%; /* Adjust width for larger screens */
    }
  }
  
  @media (min-width: 1024px) {
    .modalContent {
      width: 90%; /* Adjust width for even larger screens */
    }
  }
  
  @media (min-width: 1280px) {
    .modalContent {
      width: 90%; /* Adjust width for even larger screens */
    }
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4c4c;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .textArea,
  .fileInput {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  .modalContent::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  
  .modalContent::-webkit-scrollbar-track {
    background: #2d2d2d;
  }
  
  .modalContent::-webkit-scrollbar-thumb {
    background-color: #8fb8d9;
    border-radius: 3px;
  }


  .fileInput {
    display: block;
    margin: 20px 0;
  }
  
  .sectionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, #1f1f1f, #131313);
    padding: 15px 25px;
    margin-top: 25px;
    cursor: pointer;
    border-radius: 10px;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    color: white;
    transition: all 0.3s ease;
  }

  .sectionBar h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .sectionBar:hover {
    background: linear-gradient(to bottom, #232323, #161616);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }