/* ResearchPage.module.css */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
  font-family: 'minionpro-medium';
  src: url('../../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.researchPage {
    color: #e2e8f0;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
  }
  
  .header {
    background-color: #1a1a1a;
    padding: 2rem;
    border: 1px solid #3c3c3c; 
    border-radius: 12px; 
    width: calc(100% -70px); 
    margin: 15px;
    margin-bottom: 0px; 
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .title {
    color: #f7fafc;
    font-family: 'Outfit';
    font-size: 2.25rem;
    font-weight: bolder;
    margin-bottom: 0.5rem;
    letter-spacing: 4px;
    margin-top: 20px;
    text-align: left;
  }
  
  .title svg {
    color: #63b3ed; 
  }
  
  
  .subtitle {
    font-family: 'Outfit'; 
    color: #e6e6e6;
    font-weight: 400; 
    font-size: 1.4rem;
  }
  
  .mainContent {
    max-width: auto;
    margin: 0 auto;
    padding: 1.5rem;
  }
  
  .resultsList {
    list-style-type: none;
    padding: 0;
    display: list-item;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 10px; 
  }
  
  .resultItem {
    background-color: #1c1c1c;
    border: 1px solid #3c3c3c; 
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 1.5rem;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; 
    height: 100%;
  }
  
  .resultItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .resultTitle {
    color: #f7fafc;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .resultMeta {
    color: #a0aec0;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .resultSummary {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    flex-grow: 1;
  }
  
  .resultLink {
    background-color: #3182ce;
    border-radius: 0.25rem;
    color: #ffffff;
    display: inline-block;
    font-weight: 600;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    align-self: flex-start;
  }
  
  .resultLink:hover {
    background-color: #2c5282;
  }

  .headerBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .saveButton{
    display: flex; 
    font-size: 20px;
    background-color: #FF4500;
    border-radius: .5rem;
    color: #ffffff;
    display: inline-block;
    font-weight: 600;
    padding: 0.5rem .5rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    border: none; 
    align-self: flex-start;
    align-content: center;
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 25%;
    z-index: 1000;
  }

  .saveButton:hover {
    background-color: #a54200;
    cursor: pointer; 
  }
  
  .toolbar {
    background-color: #1a1a1a;
    padding: 1.5rem;
    border: 1px solid #3c3c3c;
    border-radius: 12px;
    width: calc(100% - 70px);
    margin: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .searchBar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 1rem;
    width: 90%; 
  }
  
  .searchInput {
    flex-grow: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #3c3c3c;
    border-right: none;
    border-radius: 0.5rem 0 0 0.5rem;
    background-color: #2d3748;
    color: #e2e8f0;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .searchInput:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
  
  .searchButton {
    background-color: #3182ce;
    color: #ffffff;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 0 0.5rem 0.5rem 0;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .searchButton:hover {
    background-color: #2c5282;
    transform: translateY(-1px);
  }
  
  .searchButton:active {
    transform: translateY(0);
  }
  
  .savedPapersButton {
    display: flex;  
    gap: 10px; 
    align-items: center; 
    background-color: #3182ce;
    color: #ffffff;
    padding: 0.6rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .savedPapersButton:hover {
    background-color: #2c5282;
    transform: translateY(-2px);
  }
  
  .savedPapersButton:active {
    transform: translateY(0);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #1e1e1e; /* Changed to match the main background color */
    color: #e2e8f0;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .modalContent h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #f7fafc;
  }
  
  .modalContent ul {
    list-style-type: none;
    padding: 0;
  }
  
  .modalContent li {
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: #2c3e50; /* Keeping this slightly lighter for contrast */
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .modalContent li:hover {
    background-color: #34495e;
  }
  
  .modalContent a {
    color: #63b3ed;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
  }
  
  .modalContent a:hover {
    color: #4299e1;
  }
  
  .modalContent button {
    background-color: #3182ce;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
    margin-top: 1.5rem;
  }
  
  .modalContent button:hover {
    background-color: #2c5282;
    transform: translateY(-2px);
  }
  
  .modalContent button:active {
    transform: translateY(0);
  }


  .header h1 {
    border-left: 5px solid #63b3ed;
    border-radius: 2px;
    padding: 0 0 0 10px; 
  }
