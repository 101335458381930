@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
  font-family: 'minionpro-medium';
  src: url('../../../fonts/minionpro-medium-webfont.woff2') format('woff2'),
       url('../../../fonts/minionpro-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


:root {
    --primary-bg: #1e1e1e;
    --secondary-bg: #222222;
    --primary-text: #e2e2e2;
    --secondary-text: #A2A2A2;
    --border-color: #6A6969;
    --highlight-bg: #85bcdb;
    --highlight-bg-hover: #6ca3c0;
    --danger-bg: #ff4c4c;
    --input-bg: #3a3a3a;
  }
  
  .containerWrapper {
    width: 100%;
    min-height: 70vh;
    background-color: #131313;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .contentArea {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .toolbar {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    max-height: 200px; 
    margin-bottom: 20px; 
    width: 97%; 
    border-bottom: #236895 1px solid;
  }

  .toolbarHeader{
    font-family: 'minionpro-medium';    
    font-weight: bold;
    font-size: 1.4rem;
    margin-right: 40px; 
    color: #ffffff;
    text-align: left;
    margin: 0px; 
    margin-right: 5px;
    letter-spacing: 2px;
  }
  
  .toolbar button {
    font-family: "Outfit";
    font-weight: bolder;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #0076c4eb;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    min-width: 100px;
  }
  
  .toolbar button:hover {
    background-color: rgb(0, 67, 154);
  }
  
  
  @media (max-width: 768px) {
    .canvas {
      grid-template-columns: 1fr;
    }
  }
  

  .helper{
    color: var(--secondary-text);
    display: inline-flex; 
    gap: 10px; 
    align-items: center;
    font-size: 2rem;
    text-align: left;
    padding: 2rem; 
  }

  .addTagsAndLinks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 40px;
    border-top: 1px solid var(--border-color);
    padding-top: 20px; 
  }
  .linkInput, .tagInput {
    width: 98%;
    padding: 0.75rem;
    margin-bottom: .5rem;
    background-color: #1c1c1c;
    color: white;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .subTitle{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 0; 
    color: var(--highlight-bg);
    text-align: left;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    color: var(--primary-text);
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-family: 'minionpro-medium';    
    display: flex; 
    align-items: center;
    gap: 1rem;
    font-size: 2.2rem;
    color: rgb(225, 225, 225);
    text-align: left;
    border-left: 5px solid #3498db;
    border-radius: 5px; 
    padding: 0px 0px 0px 20px; 
    letter-spacing: 2px;
  }
  
  .projectInfo {
    margin-bottom: .5rem;
  }
  
  .projectNameInput,
  .projectDescriptionInput{
    width: 98%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: #1c1c1c;
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }


  .urlInput{
    width: 95%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: #191919;
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  .fileInput{
    width: 95%; 
    padding: 0.75rem;
    margin-bottom: 1rem;
    color: var(--primary-text);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cellTextArea {
    width: auto;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: var(--input-bg);
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .headerTextArea {
    width: calc(100% - 80px); /* Adjust based on your button sizes */
    height: 20px;
    min-height: 20px;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background-color: #191919;
    color: var(--primary-text);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    resize: vertical;
    border: none; 
    border-bottom: 1px solid grey;

  }
  
  .cellTypeSelect {
    cursor: pointer;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: var(--input-bg);
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%;
  }
  
  .languageSelect{
    cursor: pointer;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: #191919;
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%;
  
  }
  
  .projectNameInput:focus,
  .projectDescriptionInput:focus,
  .fileInput:focus,
  .cellTypeSelect:focus {
    width: 98%; 
    outline: none;
    border-color: var(--highlight-bg);
    box-shadow: 0 0 0 2px rgba(133, 188, 219, 0.2);
  }
  
  .cellTextArea:focus {
    width: auto; 
    outline: none;
    border-color: var(--highlight-bg);
    box-shadow: 0 0 0 2px rgba(133, 188, 219, 0.2);
  }
  
  .projectDescriptionInput {
    min-height: 40px;
    resize: vertical;
  }
  
  .rowsContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .row {
    display: flex;
    gap: 1rem;
    align-items: stretch;
  }
  
  .cell {
    display: flex;
    flex-grow: 1; 
    flex-direction: column;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #191919;
    overflow: hidden;
    width: 100%;
    min-width: 0; /* This ensures the cell can shrink below its content size */
  }
  
  .cellHeader {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .header{
    margin-bottom: 1.2rem;
    margin-top: .5rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--border-color); 
    padding-bottom: 1.2rem;
  }
  
  .cellTypeSelect {
    flex-grow: 1;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  
  .removeCellButton {
    background-color: #1e1e1e;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 0.4rem 0.6rem;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .removeCellButton:hover {
    background-color: #ff6b6b;
  }
  
  .cellTextArea {
    width: 95%;
    min-height: 100px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    background-color: #191919;
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    resize: vertical;
  }
  
  .fileUploadContainer {
    width: 100%;
  }
  
  .previewContainer {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid var(--border-color);
  }
  
  .preview {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }
  
  .actionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
  }
  
  .saveButton {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .addContentBelowButton {
    background-color: #2d2d2d;
    border: 1px dashed #6a6969; 
    height: 50px; 
    width: 100%; 
    display: flex; 
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #a2a2a2; 
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative; 
    font-size: 1rem;
  }
  
  .iconSpacing { 
    margin-right: 0.5rem;
    margin-left: 0.5rem; 
  }
  
  .addContentRightButton{
    padding: 0.5rem 1rem;
    max-width: 80px;
    border: 1px dashed #6a6969;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 0.5rem;
    height: 80px;
    background-color: #2d2d2d;
    color: #a2a2a2;
    flex-direction: column; 
  }
  
  .addContentRightButton:hover,
  .addContentBelowButton:hover {
    background-color: #262626;
    transform: translateY(-2px);
  }
  
  .addContentRightButton::before,
  .addContentBelowButton::before {
    margin-right: 0.3rem;
    font-size: 1.1rem;
  }
  
  .addContentBelowButton {
    margin-right: 1rem;
  }
  
  .saveButton {
    background-color: #1c1c1c;
    color: var(--primary-text);
    border: 1px solid var(--highlight-bg);
  }
  
  .saveButton:hover {
    background-color: var(--highlight-bg);
    color: var(--primary-bg);
    transform: translateY(-2px);
  }
  
  .fileInput {
    cursor: pointer;
  }
  
  .fileInput::file-selector-button {
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    background-color: var(--secondary-bg);
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 20px;
  }
  
  .fileInput::file-selector-button:hover {
    background-color: var(--input-bg);
  }
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
    
    .cell {
      width: 100%;
    }
    
    .addContentRightButton {
      width: 100%;
      margin-top: 0.5rem;
      margin-left: 0;
    }
    
    .actionButtons {
      flex-direction: column;
      gap: 1rem;
    }
    
    .saveButton {
      width: 100%;
    }
  
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: rgb(49, 49, 49);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .deleteButton{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-bg);
    color: var(--primary-text);
    border: 1px solid var(--highlight-bg);
  }
  
  .deleteButton:hover {
    background-color: var(--highlight-bg);
    color: var(--primary-bg);
    transform: translateY(-2px);
  }
  
  .modalDeleteButton {
    background: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modalKeepButton {
    background: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50000;
    backdrop-filter: blur(5px);
  }
  
  /* Ensure the loader is visible */
  .loaderOverlay > div {
    z-index: 50001;
  }


  .autofillInput {
    display: none;
  }
  
  .autofillLabel {
    background-color: #3498db;
    display: inline-flex; 
    color: white;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    white-space: nowrap;
    max-height: 15px;
    border: 2px solid #96d5ff;
    align-items: center; 
    gap: 20px; 
  }
  
  .autofillLabel:hover {
    background-color: #236895;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .moveModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(45, 45, 45, 0.95);
    padding: 2rem;
    border-radius: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
    z-index: 1000;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    animation: fadeIn 0.3s ease-out;
    min-width: 50px;
  }

  .toggleButton {
    position: relative;
    display: inline-block;
    width: 160px;
    height: 40px;
    margin-top: 15px;
}

.toggleCheckbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6630c;
    transition: .4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.toggleSlider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.toggleCheckbox:checked + .toggleSlider {
    background-color: #3f8cc8;
}

.toggleCheckbox:checked + .toggleSlider:before {
    transform: translateX(120px);
}

.checkIcon, .timesIcon {
    color: white;
    transition: .4s;
    font-size: 18px;
}

.checkIcon {
    opacity: 0;
}

.toggleCheckbox:checked + .toggleSlider .checkIcon {
    opacity: 1;
}

.toggleCheckbox:checked + .toggleSlider .timesIcon {
    opacity: 0;
}

.toggleLabel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    transition: .4s;
    font-size: 14px;
}
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, -60%); }
    to { opacity: 1; transform: translate(-50%, -50%); }
  }
  
  .moveModal button {
    padding: 0.75rem;
    background-color: rgba(58, 58, 58, 0.8);
    color: var(--primary-text);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  
  .moveModal button:hover:not(:disabled) {
    background-color: var(--highlight-bg);
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(133, 188, 219, 0.5);
  }
  
  .moveModal button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  
  .moveModal button:active:not(:disabled) {
    transform: scale(0.95);
  }
  
  .moveModal button svg {
    width: 20px;
    height: 20px;
  }
  
  .moveModal button:nth-child(1) { grid-column: 2; }
  .moveModal button:nth-child(2) { grid-column: 1; grid-row: 2; }
  .moveModal button:nth-child(3) { grid-column: 3; grid-row: 2; }
  .moveModal button:nth-child(4) { grid-column: 2; grid-row: 3; }
  .moveModal button:nth-child(5) {
    grid-column: 1 / span 3;
    grid-row: 4;
    border-radius: 25px;
    background-color: rgba(255, 76, 76, 0.8);
    color: white;
    font-weight: bold;
    width: auto;
    height: auto;
    margin-top: 1rem;
  }
  
  .moveModal button:nth-child(5):hover {
    background-color: rgba(255, 76, 76, 1);
  }
  
  .moveCellButton {
    background-color: transparent;
    color: var(--primary-text);
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 0.4rem 0.6rem;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 10px;
    transition: all 0.3s ease;
  }
  
  .moveCellButton:hover {
    background-color: rgba(133, 188, 219, 0.2);
    border-color: var(--highlight-bg);
  }
  
  .moveLabel{
    color: var(--highlight-bg);
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-top: 0; 
    text-align: left;
  }
  
  
  .headerButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* This will right-align the content */
    gap: 20px; /* Adjust as needed */
  }
  
  .previewButton {
    background-color: #3498db;
    display: inline-flex;
    color: white;
    padding: 15px;
    border-radius: 5px;
    min-height: 10px;
    cursor: pointer;
    font-size: 20px;
    white-space: nowrap;
    border: 2px solid #96d5ff;
    align-items: center;
  }
  
  .previewButton:hover {
    background-color: #236895;
    color: white;
  }
  
  .previewModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 3000; /* Ensure it's on the highest z-index */
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  .modalContent {
    text-align: center;
  }

  /*.canvas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);    gap: 1rem;
    min-height: 300px;
    border: 2px dashed rgb(107, 107, 107); 
    border-radius: 8px;
    padding: 15px; 
    width: 100%;
    box-sizing: border-box;
  }*/

  .canvas {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    min-height: 100px;
    padding: 10px;
    transition: background-color 0.2s ease;
    border: 2px dashed rgb(107, 107, 107); 
    border-radius: 8px;
  }
  
  .canvas {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    min-height: 100px;
    padding: 10px;
    transition: background-color 0.2s ease;
  }
  
  .draggableItem {
    flex: 0 0 calc(50% - 0.5rem);
    margin-bottom: 10px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
  }
  
  .cellWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .dragging {
    opacity: 0.5;
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .dragHandle {
    cursor: move;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #383838;
    border: 1px solid #838383;
    text-align: center;
    border-radius: 4px;
  }