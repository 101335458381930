.graphPage {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  
  .noProjectsContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .navigateButton {
    padding: 15px 30px;
    font-size: 18px;
    background: linear-gradient(to bottom, #1f1f1f, #131313);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2;
    position: relative;
    transform: translateY(-100px);
  }
  
  .splineViewerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .splineViewer {
    width: 100%;
    height: 100%;
  }
  